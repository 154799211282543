import React from "react";
import { FormattedMessage } from "react-intl";
import BadgeIcon from "../../../../../../assets/images/iconos/badge.png";
import ZohoIcon from "../../../../../../assets/images/iconos/zoho.png";
import ContratoTooltip from "../../../../../../components/Contrato/ContratoTooltip";
import { APP_START_PAGE } from "../../../../../../util/defaults/app";
import ContractDetailedTooltip from "../ContractDetailedTooltip/ContractDetailedTooltip";
import StyledContratoTipoDeTrato from "../../../../components/StyledContratoTipoDeTrato";
import { TContractFull } from "../../types/contract.types";
import {
  StyledActionIcon,
  StyledActionLink,
  StyledActionLinkText,
  StyledArrowBackIosIcon,
  StyledContractStatus,
  StyledContractTitle,
  StyledContratoTitleDetails,
  StyledDivider,
  StyledFirstRow,
  StyledFirstRowLeft,
  StyledFirstRowRight,
  StyledGrowSection,
  StyledLink,
  StyledRowSection,
  StyledSecondRow,
  StyledSecondRowLeft,
  StyledSection,
  StyledTitleRowSection,
  StyledTriData,
  StyledTriDataLabel,
  StyledTriDataWrapper,
  StyledTriIcon,
  StyledTriItem,
  StyledWrapper,
} from "./ContractHeader.SC";
import { ManagerLink } from "../ManagerLink/ManagerLink";

interface IActiveContractHeaderProps {
  contract: TContractFull;
}

const ActiveContractHeader: React.FC<IActiveContractHeaderProps> = (props) => {
  const { contract } = props;

  return (
    <StyledWrapper>
      <StyledSection>
        <StyledLink
          to={APP_START_PAGE}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            window.history.back();
          }}
        >
          <StyledArrowBackIosIcon />
        </StyledLink>
      </StyledSection>
      <StyledDivider />
      <StyledGrowSection>
        <StyledFirstRow>
          <StyledFirstRowLeft>
            <StyledRowSection>
              {contract.Tipo_de_Trato && (
                <StyledContratoTitleDetails>
                  <StyledContratoTipoDeTrato tipo={contract.Tipo_de_Trato} />
                </StyledContratoTitleDetails>
              )}
            </StyledRowSection>
            <StyledRowSection>
              <ContratoTooltip contrato={contract} />
            </StyledRowSection>
            <StyledRowSection>
              <StyledContractStatus>
                <FormattedMessage
                  id="ContractHeader.Status"
                  defaultMessage="Estado:"
                />
                &nbsp;{contract.Estado}
              </StyledContractStatus>
            </StyledRowSection>
            <StyledRowSection>
              <StyledActionLink
                color="primary"
                onClick={() => {
                  window.open(
                    `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contract.zohoId}`
                  );
                }}
              >
                <StyledActionIcon src={ZohoIcon} />
                <StyledActionLinkText>
                  <FormattedMessage
                    id="ContractHeader.ViewInZoho"
                    defaultMessage="Ver en Zoho"
                  />
                </StyledActionLinkText>
              </StyledActionLink>
            </StyledRowSection>
            <StyledRowSection>
              <ManagerLink zohoId={contract.zohoId} type="link" />
            </StyledRowSection>
          </StyledFirstRowLeft>
          <StyledFirstRowRight>
            {!!contract.Servicio?.ownerName && (
              <StyledRowSection>
                <StyledTriItem>
                  <StyledTriIcon src={BadgeIcon} />
                  <StyledTriDataWrapper>
                    <StyledTriDataLabel>
                      <FormattedMessage
                        id="ContractHeader.Propietario servicio"
                        defaultMessage="Propietario servicio"
                      />
                    </StyledTriDataLabel>
                    <StyledTriData>{contract.Servicio.ownerName}</StyledTriData>
                  </StyledTriDataWrapper>
                </StyledTriItem>
              </StyledRowSection>
            )}
            <StyledRowSection>
              <StyledTriItem>
                <StyledTriIcon src={BadgeIcon} />
                <StyledTriDataWrapper>
                  <StyledTriDataLabel>
                    <FormattedMessage
                      id="ContractHeader.Propietario contrato"
                      defaultMessage="Propietario contrato"
                    />
                  </StyledTriDataLabel>
                  <StyledTriData>{contract.ownerName}</StyledTriData>
                </StyledTriDataWrapper>
              </StyledTriItem>
            </StyledRowSection>
          </StyledFirstRowRight>
        </StyledFirstRow>
        <StyledSecondRow>
          <StyledSecondRowLeft>
            <StyledTitleRowSection>
              <StyledContractTitle>{contract.T_tulo}</StyledContractTitle>
              <ContractDetailedTooltip contract={contract} />
            </StyledTitleRowSection>
          </StyledSecondRowLeft>
        </StyledSecondRow>
      </StyledGrowSection>
    </StyledWrapper>
  );
};

export default ActiveContractHeader;
