import { useQuery, UseQueryOptions } from "react-query";
import {
  apiGetCoreV3ContractByCrmId,
  TContractResponse,
} from "../../../../../apiCoreV3/contract/apiGetContract";

export const useGetCorev3Contracts = (
  contractCrmId: string | undefined,
  configOptions?: UseQueryOptions<TContractResponse, Error, TContractResponse>
) => {
  return useQuery<TContractResponse, Error>({
    queryKey: ["corev3-contract", contractCrmId],
    queryFn: async () => {
      if (!contractCrmId) {
        throw new Error("contractCrmId is undefined");
      }
      const response = await apiGetCoreV3ContractByCrmId(contractCrmId);
      return response;
    },
    ...configOptions,
  });
};
