import { Box, Button, Tab, Tabs } from "@material-ui/core";
import { push } from "connected-react-router";
import { motion } from "framer-motion";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  filtersResetContrato,
  filtersResetContratoSuggestions,
  filtersSetContrato,
  getCandidato,
  modalShow,
  setBackground,
  snackSuccessMessage,
} from "../../../../actions";
import { apiUpdateContractPhase } from "../../../../api/contract/apiUpdateContractPhase";
import {
  CONTRACT_CANDIDATE_SORT_ORDER,
  CONTRACT_PHASE,
  TGetContractsItemValuation,
} from "../../../../api/contract/contract.types";
import {
  TValuationStatus,
  VALUATION_STATUS,
} from "../../../../api/valuation/valuation.types";
import Error404 from "../../../../components/Error404";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import { useAuthUser } from "../../../../reducers/hooks/useAuthUser";
import { apiEmpleo } from "../../../../util/ApiEmpleo";
import {
  decorateContratoWithNumbers,
  isContractOriginWarrantyZeroOrChangeEmployer,
} from "../../../../util/Contrato/util";
import {
  TIPO_EXTERNA_ENTRE_SEMANA,
  TIPO_EXTERNA_FIN_DE_SEMANA,
  TIPO_INTERNA_ENTRE_SEMANA,
  TIPO_INTERNA_FIN_DE_SEMANA,
} from "../../../../util/defaults/contrato";
import {
  SUGGESTION_ACCEPTED,
  SUGGESTION_REJECTED,
  SUGGESTION_SUBSTITUTE,
  VALORACION_CONTRATADO,
  VALORACION_DESCARTADO,
  VALORACION_PRESENCIAL,
  VALORACION_RECOMENDACION,
  VALORACION_SUGGESTION,
} from "../../../../util/defaults/valoracion";
import { reportError } from "../../../../util/errorReporting";
import { hasSearchText } from "../../../../util/search";
import useBackground from "../../../../util/useBackground";
import useFooter from "../../../../util/useFooter";
import useHeader from "../../../../util/useHeader";
import usePageTitle from "../../../../util/usePageTitle";
import {
  decorateValoracionesWithScore,
  sortValoracionesByAntiguas,
  sortValoracionesByFechaFinUltimoContrato,
  sortValoracionesByRecientes,
  sortValoracionesByScore,
} from "../../../../util/Valoracion/util";
import CandidatoFullProfile from "../../components/CandidatoFullProfile";
import ContratoFilters from "../../components/ContractFilters/ContractFilters";
import ContratoValoracionesMap from "../../components/ContratoValoracionesMap";
import Conversation from "../../components/Conversation/Conversation";
import ValoracionesList from "../../components/ValoracionesList";
import ValoracionesMultiSelectFooter from "../../components/ValoracionesMultiSelectFooter";
import VariableContainer from "../../components/VariableContainer";
import { TOnCandidateChangeFn } from "../../features/candidate/types/candidate.types";
import ContractHeader from "../../features/contract/components/ContractHeader";
import { TUpdateContractPhase } from "../../features/contract/routes/ContractsPage/hooks/useContractPhaseMutation";
import { TOnContractValuationChangeFn } from "../../features/contract/types/contract-valuation.types";
import { TContractValuation } from "../../features/valuation/types/valuation.types";
import CvFollowUpDialog from "./components/CvFollowUpDialog";
import ToPromotePhaseDialog from "./components/ToPromotePhaseDialog";
import useContractPagePermissions from "./hooks/useContractPagePermissions";
import useCvFollowUpState from "./hooks/useCvFollowUpState";
import useNotificationsDiscard from "./hooks/useNotificationsDiscard";
import useContractPageUrlParams, {
  initialState,
  resetStateSuggestions,
} from "./useContractPageUrlParams";
import {
  EMPLOYEE_AVAILABILITY,
  EMPLOYEE_STATUS,
} from "../../../../api/employee/employee.types";
import moment from "moment";
import { outstandingIsSelected } from "../../components/ContractFilters/ContractFiltersHelper";
import StarIcon from "@material-ui/icons/Star";
import { YELLOW } from "../../../../constants/colors";
import ContractToSelectedStateDialog from "./components/ContractToSelectedStateDialog";
import {
  apiCoreV3ContractSendAlertNotes,
  apiUpdateContractToSelectedState,
} from "../../../../api/contract/apiUpdateContractToSelectedState";
import { apiUpdateValuationState } from "../../../../api/valuation/apiUpdateValuationState";
import { userIsCommercialSalesCompany } from "../../models/Staff/Staff";

const MAX_NUMBER_ADVANCED_VALUATIONS = 5;

const REQUEST_ITEMS_PER_PAGE = 100;
const MAX_CANDIDATOS_ON_MAP = 100;
const ITEMS_PER_PAGE = 10;

export const LIST_VALORACIONES_REMOVING = "list_valoraciones_removing";
export const LIST_VALORACIONES_REMOVED = "list_valoraciones_removed";

const StyledPageContainer = styled.div<{ appMarginTop: number }>`
  height: 100vh;
  overflow-y: hidden;

  ${({ appMarginTop }) => {
    return `
    margin: ${appMarginTop}rem auto 0;
  `;
  }}

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    padding: 0;
    background-color: ${cuideoTheme.palette.common.white};
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .md}px) and (max-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
    margin-left: auto;
    margin-right: auto;
    margin: 0;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
    margin-left: auto;
    margin-right: auto;
    margin: 0;
  }
`;

const StyledBox = styled(Box)`
  background-color: ${cuideoTheme.palette.common.white};
`;

const StyledDestacadosButton = styled(Button)`
  background-color: ${cuideoTheme.palette.common.white};
  min-width: 28rem;
  margin-right: 16px;
  border: 2px solid transparent;
  border-radius: 0;
  text-transform: none;
  font-weight: 400;
  line-height: 1.15;
  color: ${cuideoTheme.palette.primary.main};

  &: hover {
    background-color: ${cuideoTheme.palette.common.white};
    border: 2px solid rgb(51, 111, 149);
  }

  &.selected {
    border-bottom: 2px solid rgb(51, 111, 149);
    font-weight: 700;
  }
`;

const StyledStarIcon = styled(StarIcon)`
  color: ${YELLOW};
`;

const StyledEstadosTabs = styled(Tabs)`
  background-color: ${cuideoTheme.palette.common.white};
  position: relative;
  border-top: 1px solid #e7e7e7;
  flex-grow: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 3px;
    left: 0;
    right: 0;
    background-color: #efefef;
    z-index: 0;
  }

  .Mui-selected {
    font-weight: 700;
  }
  .MuiTabs-indicator {
    background-color: rgba(246, 74, 105, 1);
    height: 4px;
    bottom: -1px;
    z-index: 1;
  }
`;

const StyledEstadoTab = styled(Tab)`
  text-transform: none;
  font-weight: 400;
  line-height: 1.15;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0.3125rem 0.75rem;

  .MuiTab-wrapper {
    white-space: nowrap;
    display: block;
    font-size: 1.0625rem;

    .MuiTab-CuideoNumber {
      font-size: 1.25rem;
    }
  }
`;

const StyledFiltersWrapper = styled.div``;

const StyledCandidatoContainer = styled.div`
  height: 100%;
`;

const StyledListPane = styled.div`
  position: relative;
`;

const StyledMapPane = styled.div`
  position: relative;
`;

const StyledCandidatoPane = styled.div`
  position: relative;

  overflow-y: hidden;
`;

const headerVariants = {
  enter: {
    opacity: 1,
    transition: { duration: 0.25, delay: 0.05 },
    y: 0,
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1 },
    y: -15,
  },
};

const valuationsVariants = {
  enter: {
    opacity: 1,
    transition: { duration: 0.25, delay: 0.2 },
    x: 0,
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1 },
    x: -15,
  },
};

const mapVariants = {
  enter: {
    opacity: 1,
    transition: { duration: 0.25, delay: 0.2 },
    x: 0,
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1 },
    x: 15,
  },
};

type Props = {
  match: any;
  location: any;
  history: any;
  getCandidato: any;
  candidatos: any;
  modalShow: any;
};

const ContractPage = ({
  match,
  location,
  history,
  getCandidato,
  candidatos,
  modalShow,
}: Props) => {
  const [contrato, setContrato] = useState(null as any);
  const [contractBlocked, setContractBlocked] = useState(false);
  const [contractNotFound, setContractNotFound] = useState(false);
  const [valoraciones, setValoraciones] = useState([] as any);
  const [filteredValoraciones, setFilteredValoraciones] = useState([] as any);
  const [selectedValoracion, setSelectedValoracion] = useState(null as any);
  const [hoveredValoracion, setHoveredValoracion] = useState(null as any);
  const [selectedValoraciones, setSelectedValoraciones] = useState([] as any[]);
  const [selectAll, setSelectAll] = useState(false);
  const { authUser } = useAuthUser();
  useNotificationsDiscard(contrato, authUser);
  const {
    cvFollowUpState,
    initStateFromContract,
    sendMail,
    callClient,
    noAnswerSendMail,
    alreadyTalkedWithClient,
    confirmFollowUp,
  } = useCvFollowUpState();
  const [map, setMap] = useState({
    center: {
      lat: 41.3788,
      lng: 2.1863,
    },
    zoom: 13,
    bounds: undefined as any,
    marginBounds: undefined as any,
    size: undefined as any,
  });
  const [
    showContractToSelectedStateDialog,
    setShowContractToSelectedStateDialog,
  ] = useState<boolean>(false);
  const [
    isContractToSelectedStateDialogRequestLoading,
    setIsContractToSelectedStateDialogRequestLoading,
  ] = useState<boolean>(false);
  const [contractedValoracion, setContractedValoracion] = useState(null as any);

  const [toPromotePhase, setToPromotePhase] = useState({
    dialogOpen: false,
    isUpdating: false,
  });
  const [loadingValoraciones, setLoadingValoraciones] = useState(false);
  const [loadingValoracionesParams, setLoadingValoracionesParams] = useState(
    {} as any
  );
  const { formatMessage } = useIntl();
  const { filters, setFilters } = useContractPageUrlParams(history);
  // eslint-disable-next-line
  // const [contractNumbers, setContractNumbers] = useContractNumbersStorage(
  //   contrato ? contrato.id : ""
  // );
  const permissions = useContractPagePermissions();

  const [isOutstandingSelected, setIsOutstandingSelected] =
    useState<boolean>(false);

  useEffect(() => {
    setIsOutstandingSelected(outstandingIsSelected(filters));
  }, [filters]);

  useHeader({
    type: "hidden",
  });
  useFooter({
    type: "hidden",
  });
  useBackground("cuideo");

  usePageTitle(
    contrato && contrato.Name
      ? contrato.Name
      : formatMessage({
          id: "ContractPage.Contract",
          defaultMessage: "Contrato",
        })
  );

  const getContrato = useCallback(
    async (params: any) => {
      let res;
      try {
        res = await apiEmpleo.get("offers/" + match.params.contratoId, {
          params,
        });
        if (
          userIsCommercialSalesCompany(authUser) &&
          authUser.userSalesCompany &&
          (!res.Delegacion_comercial ||
            (res.Delegacion_comercial &&
              authUser.userSalesCompany !== res.Delegacion_comercial))
        ) {
          setContractNotFound(true);
          reportError(
            `Contract ${match.params.contratoId} not found`,
            "Affinity Contract Page",
            290
          );
          return;
        }
      } catch (e) {
        // @ts-ignore
        if (e.response.status === 404) {
          setContractNotFound(true);
          reportError(
            `Contract ${match.params.contratoId} not found`,
            "Affinity Contract Page",
            290
          );
        }
      }

      if (!res) {
        return;
      }

      const decoContrato = decorateContratoWithNumbers(res);
      if (!decoContrato.Latitud || !decoContrato.Longitud) {
        if (
          !decoContrato.C_digo_Postal_Oferta &&
          decoContrato.Servicio &&
          decoContrato.Servicio.C_digo_postal_servicio
        ) {
          decoContrato.C_digo_Postal_Oferta =
            decoContrato.Servicio.C_digo_postal_servicio;
        }
        // Check if we have Latitude and Longitude and set them
        let geocodeString = "";
        if (
          decoContrato.C_digo_Postal_Oferta &&
          decoContrato.Servicio &&
          decoContrato.Servicio.Ciudad
        ) {
          geocodeString += `${res.C_digo_Postal_Oferta} ${decoContrato.Servicio.Ciudad}, `;
        } else if (decoContrato.C_digo_Postal_Oferta) {
          geocodeString += `${res.C_digo_Postal_Oferta}, `;
        }
        if (decoContrato.Provincia_oferta) {
          geocodeString += `${res.Provincia_oferta}, `;
        }
        geocodeString += "España";
        try {
          let res2 = await geocodeByAddress(geocodeString);
          let latlng: any = await getLatLng(res2[0]);
          setMap((prevMap: any) => {
            return {
              ...prevMap,
              center: {
                lat: parseFloat(latlng.lat),
                lng: parseFloat(latlng.lng),
              },
            };
          });
          decoContrato.Latitud = latlng.lat;
          decoContrato.Longitud = latlng.lng;
          decoContrato.ubiType = "postal_code";
        } catch (e) {
          reportError(
            `Error geocoding ${geocodeString} for ${decoContrato.Name}`,
            "Affinity Contract Page - Geocoding contract",
            311
          );
          modalShow({
            open: true,
            type: "error",
            message: formatMessage({
              id: "ContractPage.UbicationError",
              defaultMessage:
                "No se ha localizado la ubicación del contrato en el mapa. Esto puede ser debido a que no esté el código postal o la provincia bien introducida en el contrato.",
            }),
          });
        }
      } else {
        setMap((prevMap: any) => {
          return {
            ...prevMap,
            center: {
              lat: parseFloat(decoContrato.Latitud),
              lng: parseFloat(decoContrato.Longitud),
            },
          };
        });
      }
      initStateFromContract(decoContrato, authUser);
      setContrato(decoContrato);
      const contractedValuation = decoContrato.valoraciones.find(
        (item: any) => {
          return item.Estado_en_proceso === VALORACION_CONTRATADO;
        }
      );
      if (contractedValuation) {
        setContractBlocked(true);
      } else {
        setContractBlocked(false);
      }
      // (setContractNumbers as any)({
      //   id: decoContrato.id,
      //   lastUpdated: new Date(),
      //   numInscritos: decoContrato.numInscritos,
      //   numDiscarded: decoContrato.numDescartados,
      //   numSuggestions: decoContrato.numSuggestions,
      //   numSuggestionsAccepted: 0,
      //   numSuggestionsRejected: 0,
      //   numWithoutVal: decoContrato.numSinValorar,
      //   numPhone: decoContrato.numTelefonica,
      //   numFaceToFace: decoContrato.numPresencial,
      //   numCvClient: decoContrato.numCVCliente,
      //   numFaceToFaceClient: decoContrato.numPresencialCliente,
      //   numContracted: decoContrato.numContratado,
      // });

      window.dispatchEvent(new Event("resize"));

      return decoContrato;
    },
    // eslint-disable-next-line
    [match.params.contratoId, modalShow]
  );

  useEffect(() => {
    if (!contrato) {
      getContrato({});
    }
    // eslint-disable-next-line
  }, [match.params.contratoId, location, history]);

  const getValoraciones = useCallback(
    async (params: any, force = false, contract = null) => {
      const cont = contract || contrato;
      if (!cont) {
        return;
      }

      const tempParams = {
        estadoEnProceso: filters.estadoEnProceso,
        itemsPerPage: REQUEST_ITEMS_PER_PAGE,
        partial: 1,
      };

      const procParams = { ...tempParams, ...params } as any;
      // If we are using suggestions we include the filters in the query
      if (
        procParams.estadoEnProceso === VALUATION_STATUS.SEARCH ||
        procParams.estadoEnProceso === VALUATION_STATUS.OUTSTANDING
      ) {
        if (filters.sexo) {
          procParams.sexo = filters.sexo;
        }
        if (filters.estado.length) {
          procParams.estadoCuideo = filters.estado;
        }
        if (filters.felizVitaStatus.length) {
          procParams.felizVitaStatus = filters.felizVitaStatus;
        }
        if (filters.interviewStatus.length) {
          procParams.interviewStatus = filters.interviewStatus;
        }
        if (filters.workRegime.length) {
          procParams.hasWorkRegime = filters.workRegime.join(",");
        }
        if (filters.titulacion === "si") {
          procParams["exists[titulacionOficialSAD]"] = "true";
        }
        if (filters.titulacion === "no") {
          procParams["exists[titulacionOficialSAD]"] = "false";
        }
        if (filters.tipo.includes(TIPO_INTERNA_ENTRE_SEMANA)) {
          procParams["internaEntreSemana"] = 1;
        }
        if (filters.tipo.includes(TIPO_INTERNA_FIN_DE_SEMANA)) {
          procParams["internaFinSemana"] = 1;
        }
        if (filters.tipo.includes(TIPO_EXTERNA_ENTRE_SEMANA)) {
          procParams["externaEntreSemana"] = 1;
        }
        if (filters.tipo.includes(TIPO_EXTERNA_FIN_DE_SEMANA)) {
          procParams["externaFinSemana"] = 1;
        }
        if (filters.botAvailable === "yes") {
          procParams["botAvailable"] = 1;
        }
        if (filters.botAvailable === "no") {
          procParams["botAvailable"] = 0;
        }
        if (filters.carnet !== "") {
          procParams.carnetDeConducir = filters.carnet;
        }
        if (filters.vehiculo !== "") {
          procParams.vehiculoPropio = filters.vehiculo;
        }
        if (filters.lugarDeNacimiento !== "") {
          procParams.lugarDeNacimiento = filters.lugarDeNacimiento;
        }
        if (filters.search !== "" && filters.search !== "undefined") {
          procParams.searchFor = filters.search;
        }
        if (filters.inOpenProcess === "no") {
          procParams["numOpenValuationProcesses[lt]"] = 1;
        }
        if (filters.inOpenProcess === "yes") {
          procParams["numOpenValuationProcesses[gte]"] = 1;
        }
        if (filters.inRecentProcesses === "last7") {
          procParams["numDaysSinceEnteredValuationProcess[lte]"] = 7;
        }
        if (filters.inRecentProcesses === "last14") {
          procParams["numDaysSinceEnteredValuationProcess[lte]"] = 14;
        }
        if (filters.inRecentProcesses === "last30") {
          procParams["numDaysSinceEnteredValuationProcess[lte]"] = 30;
        }

        if (filters.company === "FV") {
          params.company = "FV";
        }
        if (filters.company === "Cuideo") {
          params["company[exists]"] = 0;
        }

        procParams.age = [];
        if (filters.edad.includes("18-25")) {
          procParams.age = [
            ...procParams.age,
            ...[18, 19, 20, 21, 22, 23, 24, 25],
          ];
        }
        if (filters.edad.includes("26-35")) {
          procParams.age = [
            ...procParams.age,
            ...[26, 27, 28, 29, 30, 31, 32, 33, 34, 35],
          ];
        }
        if (filters.edad.includes("36-45")) {
          procParams.age = [
            ...procParams.age,
            ...[36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
          ];
        }
        if (filters.edad.includes("46-55")) {
          procParams.age = [
            ...procParams.age,
            ...[46, 47, 48, 49, 50, 51, 52, 53, 54, 55],
          ];
        }
        if (filters.edad.includes("56-60")) {
          procParams.age = [...procParams.age, ...[56, 57, 58, 59, 60]];
        }
        if (filters.edad.includes("61-65")) {
          procParams.age = [...procParams.age, ...[61, 62, 63, 64, 65]];
        }
        if (filters.edad.includes("+65")) {
          procParams.age = [...procParams.age, ...[66, 67, 68, 69, 70]];
        }
        if (filters.tasks.length) {
          if (filters.tasks.length > 1) {
            procParams.hasTasks = filters.tasks.join(",");
          } else {
            procParams.hasTasks = filters.tasks[0];
          }
        }
        if (filters.languages.length) {
          if (filters.languages.length > 1) {
            procParams.hasLanguages = filters.languages.join(",");
          } else {
            procParams.hasLanguages = filters.languages[0];
          }
        }
        if (filters.pathologies.length) {
          if (filters.pathologies.length > 1) {
            procParams.hasPathologies = filters.pathologies.join(",");
          } else {
            procParams.hasPathologies = filters.pathologies[0];
          }
        }
        if (filters.experience.length) {
          procParams.yearsOfExperience = filters.experience;
        }
        if (filters.availability.length) {
          procParams.availability = filters.availability;
        }
        if (filters.applyByGoalDate !== "") {
          let filtersGoalDate: any = filters.applyByGoalDate;
          if (typeof filtersGoalDate !== "object") {
            filtersGoalDate = moment(filters.applyByGoalDate).toDate();
          }
          procParams.applyByGoalDate = filtersGoalDate;
        }
      }

      if (!_.isEqual(procParams, loadingValoracionesParams) || force) {
        setLoadingValoracionesParams(procParams);
        const finalParams = Object.assign({}, procParams);
        finalParams.ts = Date.now();

        setLoadingValoraciones(true);
        if (
          filters.estadoEnProceso === VALUATION_STATUS.SEARCH ||
          filters.estadoEnProceso === VALUATION_STATUS.OUTSTANDING
        ) {
          if (finalParams["latitud[gt]"]) {
            let res = await apiEmpleo.get(`candidatos/suggestions`, {
              params: {
                ...finalParams,
                "order[employeeScore]": "DESC",
              },
            });
            const suggestedCandidatos: any[] = [];

            if (res["hydra:member"].length >= REQUEST_ITEMS_PER_PAGE) {
              // If we have 100 or more we try to merge with favorite candidates
              let resFav = await apiEmpleo.get(`candidatos/suggestions`, {
                params: {
                  ...finalParams,
                  fav: "myFav",
                  "order[employeeScore]": "DESC",
                },
              });
              suggestedCandidatos.push(...resFav["hydra:member"]);
            }

            const filteredSuggestedCandidatos = res["hydra:member"].filter(
              (candidato: any) => {
                const existingCandidate = suggestedCandidatos.find(
                  (existingCandidato: any) =>
                    existingCandidato.id === candidato.id
                );

                if (existingCandidate) {
                  return false;
                }
                return true;
              }
            );

            suggestedCandidatos.push(...filteredSuggestedCandidatos);

            const tempValoraciones = [] as any;
            suggestedCandidatos.forEach((candidato: any) => {
              const candidatoHasValidacion = contrato.valoraciones.find(
                (item: any) => {
                  // We have some valuations without employee candidate
                  if (item.candidato && item.candidato.id === candidato.id) {
                    return true;
                  }
                  return false;
                }
              );
              if (!candidatoHasValidacion) {
                const newVal = {
                  id: `tc-${candidato.id}`,
                  Estado_en_proceso: VALORACION_RECOMENDACION,
                  candidato: candidato,
                  contrato: {
                    id: cont.id,
                    Ciudad_oferta: cont.Ciudad_oferta,
                    Ciudad_servicio: cont.Ciudad_servicio,
                    Horario_Servicio: cont.Horario_Servicio,
                    Salario_Bruto_Mensual: cont.Salario_Bruto_Mensual,
                    numCommunicationIntents: cont.numCommunicationIntents,
                    Squad: cont.Squad,
                  },
                };
                tempValoraciones.push(newVal);
              } else {
                const newVal = {
                  id: `tc-${candidato.id}`,
                  Estado_en_proceso: VALORACION_RECOMENDACION,
                  candidato: candidato,
                  contrato: {
                    id: cont.id,
                    Ciudad_oferta: cont.Ciudad_oferta,
                    Ciudad_servicio: cont.Ciudad_servicio,
                    Horario_Servicio: cont.Horario_Servicio,
                    Salario_Bruto_Mensual: cont.Salario_Bruto_Mensual,
                    Squad: cont.Squad,
                  },
                  alreadyAdded: true,
                };
                tempValoraciones.push(newVal);
              }
            });
            const valsWithScore = decorateValoracionesWithScore(
              tempValoraciones,
              cont,
              authUser.userId
            );

            if (!_.isEqual(valsWithScore, valoraciones)) {
              setValoraciones(valsWithScore);
            }
          }
        } else if (filters.estadoEnProceso === VALORACION_SUGGESTION) {
          let res = await apiEmpleo.get(
            `contratos/${contrato.id}/suggestions`,
            {
              params: {
                ...finalParams,
                // "order[employeeScore]": "DESC",
              },
            }
          );
          const suggestions = res["hydra:member"];
          const tempValuations = [] as any;
          suggestions
            .filter((suggestion: any) => {
              if (suggestion.status === SUGGESTION_ACCEPTED) {
                return false;
              }
              if (suggestion.status === SUGGESTION_REJECTED) {
                return false;
              }
              if (suggestion.status === SUGGESTION_SUBSTITUTE) {
                return false;
              }
              return true;
            })
            .forEach((suggestion: any) => {
              // eslint-disable-next-line
              const hasValuation = contrato.valoraciones.find((item: any) => {
                if (
                  item.candidato &&
                  item.candidato.id === suggestion.employee.id
                ) {
                  return true;
                }
              });
              tempValuations.push({
                id: suggestion.id,
                Estado_en_proceso: VALORACION_SUGGESTION,
                candidato: { ...suggestion.employee },
                contrato: {
                  id: cont.id,
                },
                suggestionType: suggestion.type,
                suggestionScore: suggestion.score,
                suggestionStatus: suggestion.status,
                suggestionIssues: suggestion.issues,
                alreadyAdded: !!hasValuation,
              });
            });
          const valsWithScore = decorateValoracionesWithScore(
            tempValuations,
            cont,
            authUser.userId
          );

          if (!_.isEqual(valsWithScore, valoraciones)) {
            setValoraciones(valsWithScore);
          }
        } else {
          const tempFilteredVals = cont.valoraciones.filter((item: any) => {
            if (item.Estado_en_proceso === finalParams.estadoEnProceso) {
              return true;
            }
            return false;
          });
          const valsWithScore = decorateValoracionesWithScore(
            tempFilteredVals,
            cont,
            authUser.userId
          );

          if (!_.isEqual(valsWithScore, valoraciones)) {
            setValoraciones(valsWithScore);
          }
        }
        setLoadingValoraciones(false);
      }
    },
    // eslint-disable-next-line
    [loadingValoracionesParams, setLoadingValoracionesParams, contrato, filters]
  );

  useEffect(() => {
    if (
      filters.estadoEnProceso === VALUATION_STATUS.SEARCH ||
      filters.estadoEnProceso === VALUATION_STATUS.OUTSTANDING
    ) {
      if (map.bounds && map.bounds.nw && map.bounds.se) {
        const params = {
          "latitud[gt]": map.bounds.se.lat,
          "latitud[lt]": map.bounds.nw.lat,
          "longitud[gt]": map.bounds.nw.lng,
          "longitud[lt]": map.bounds.se.lng,
        } as any;
        if (filters.estado.length) {
          params.estadoCuideo = filters.estado;
        }

        // ON LOAD PAGE WITH OUTSTANDING STATUS > SET FILTERS
        if (
          contrato !== null &&
          filters.estadoEnProceso === VALUATION_STATUS.OUTSTANDING &&
          filters.estado.length > 1 &&
          filters.applyByGoalDate === ""
        ) {
          const DAYS_BEFORE_CONTRACT_CV_CLIENT_DATE = 3;
          const goalDate = moment(contrato.Fecha_cv_cliente)
            .subtract(DAYS_BEFORE_CONTRACT_CV_CLIENT_DATE, "days")
            .toDate();
          setFilters({
            ...filters,
            estadoEnProceso: VALUATION_STATUS.OUTSTANDING,
            estado: [EMPLOYEE_STATUS.VALIDATED_PLUS],
            orden: CONTRACT_CANDIDATE_SORT_ORDER.LAST_CONTRACT_END_DATE,
            applyByGoalDate: goalDate.toISOString(),
            inOpenProcess: "",
            botAvailable: "",
            availability: [],
          });
          return;
        }
        getValoraciones(params);
      }
    } else {
      getValoraciones({}, true);
    }
    // eslint-disable-next-line
  }, [filters, history, location, map, contrato]);

  useEffect(() => {
    if (filters.selectedValuationId) {
      const foundValuation = valoraciones.find(
        (val: any) => val.id === filters.selectedValuationId
      );
      if (foundValuation) {
        setSelectedValoracion({ ...foundValuation });
        if (foundValuation.candidato && foundValuation.candidato.id) {
          getCandidato(foundValuation.candidato.id);
        }
      }
    }
    // eslint-disable-next-line
  }, [valoraciones, filters.selectedValuationId]);

  useEffect(() => {
    const filterValoraciones = (valoraciones: any) => {
      const filtered = valoraciones.filter((valoracion: any) => {
        // If it is mutating we filter it
        if (valoracion.status && valoracion.status === "mutating") {
          return false;
        }

        if (filters.sexo === "Hombre") {
          if (!valoracion.candidato || valoracion.candidato.Sexo !== "Hombre") {
            return false;
          }
        }
        if (filters.sexo === "Mujer") {
          if (!valoracion.candidato || valoracion.candidato.Sexo !== "Mujer") {
            return false;
          }
        }
        if (filters.carnet === "SI") {
          if (
            !valoracion.candidato ||
            valoracion.candidato.Carnet_de_conducir !== "SI"
          ) {
            return false;
          }
        }
        if (filters.carnet === "NO") {
          if (
            !valoracion.candidato ||
            valoracion.candidato.Carnet_de_conducir !== "NO"
          ) {
            return false;
          }
        }
        if (filters.vehiculo === "Si") {
          if (
            !valoracion.candidato ||
            valoracion.candidato.Veh_culo_propio !== "Si"
          ) {
            return false;
          }
        }
        if (filters.vehiculo === "No") {
          if (
            !valoracion.candidato ||
            valoracion.candidato.Veh_culo_propio !== "No"
          ) {
            return false;
          }
        }
        if (filters.lugarDeNacimiento !== "") {
          if (
            !valoracion.candidato ||
            valoracion.candidato.Lugar_de_nacimiento !==
              filters.lugarDeNacimiento
          ) {
            return false;
          }
        }
        if (filters.inOpenProcess === "yes") {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.numOpenValuationProcesses ||
            !!(valoracion.candidato.numOpenValuationProcesses < 1)
          ) {
            return false;
          }
        }
        if (filters.inOpenProcess === "no") {
          if (
            !valoracion.candidato ||
            !(valoracion.candidato.numOpenValuationProcesses === 0) ||
            !!(valoracion.candidato.numOpenValuationProcesses > 0)
          ) {
            return false;
          }
        }
        if (filters.inRecentProcesses === "last7") {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.numDaysSinceEnteredValuationProcess ||
            !!(valoracion.candidato.numDaysSinceEnteredValuationProcess > 7)
          ) {
            return false;
          }
        }
        if (filters.inRecentProcesses === "last14") {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.numDaysSinceEnteredValuationProcess ||
            !!(valoracion.candidato.numDaysSinceEnteredValuationProcess > 14)
          ) {
            return false;
          }
        }
        if (filters.inRecentProcesses === "last30") {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.numDaysSinceEnteredValuationProcess ||
            !!(valoracion.candidato.numDaysSinceEnteredValuationProcess > 30)
          ) {
            return false;
          }
        }
        if (filters.botAvailable === "yes") {
          if (!valoracion.candidato || !valoracion.candidato.botAvailable) {
            return false;
          }
        }
        if (filters.botAvailable === "no") {
          if (!valoracion.candidato || !!valoracion.candidato.botAvailable) {
            return false;
          }
        }

        let validAge = [] as number[];
        if (filters.edad.includes("18-25")) {
          validAge = [...validAge, ...[18, 19, 20, 21, 22, 23, 24, 25]];
        }
        if (filters.edad.includes("26-35")) {
          validAge = [...validAge, ...[26, 27, 28, 29, 30, 31, 32, 33, 34, 35]];
        }
        if (filters.edad.includes("36-45")) {
          validAge = [...validAge, ...[36, 37, 38, 39, 40, 41, 42, 43, 44, 45]];
        }
        if (filters.edad.includes("46-55")) {
          validAge = [...validAge, ...[46, 47, 48, 49, 50, 51, 52, 53, 54, 55]];
        }
        if (filters.edad.includes("56-60")) {
          validAge = [...validAge, ...[56, 57, 58, 59, 60]];
        }
        if (filters.edad.includes("61-65")) {
          validAge = [...validAge, ...[61, 62, 63, 64, 65]];
        }
        if (filters.edad.includes("+65")) {
          validAge = [...validAge, ...[66, 67, 68, 69, 70]];
        }
        if (validAge.length > 0) {
          if (
            !valoracion.candidato ||
            !validAge.includes(valoracion.candidato.age)
          ) {
            return false;
          }
        }
        if (filters.origen === "web") {
          if (!valoracion.Candidato_Web) {
            return false;
          }
        }
        if (filters.origen === "cuideo") {
          if (valoracion.Candidato_Web) {
            return false;
          }
        }
        if (filters.titulacion === "si") {
          if (
            !valoracion.candidato ||
            valoracion.candidato.Titulaci_n_oficial_SAD.length === 0
          ) {
            return false;
          }
        }
        if (filters.titulacion === "no") {
          if (
            !valoracion.candidato ||
            valoracion.candidato.Titulaci_n_oficial_SAD.length !== 0
          ) {
            return false;
          }
        }
        if (filters.estado.length) {
          if (
            !valoracion.candidato ||
            !filters.estado.includes(valoracion.candidato.Estado_Cuideo)
          ) {
            return false;
          }
        }
        if (filters.felizVitaStatus.length) {
          if (
            !valoracion.candidato ||
            !filters.felizVitaStatus.includes(valoracion.candidato.Estado_FV)
          ) {
            return false;
          }
        }
        if (filters.interviewStatus.length) {
          if (
            !valoracion.candidato ||
            !filters.interviewStatus.includes(
              valoracion.candidato.interviewStatus
            )
          ) {
            return false;
          }
        }
        if (filters.workRegime.length) {
          if (
            !valoracion.candidato ||
            _.difference(
              filters.workRegime,
              valoracion.candidato.R_gimen_de_trabajo
            ).length !== 0
          ) {
            return false;
          }
        }
        if (filters.tipo.includes(TIPO_INTERNA_ENTRE_SEMANA)) {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.Interna_entre_semana
          ) {
            return false;
          }
        }
        if (filters.tipo.includes(TIPO_INTERNA_FIN_DE_SEMANA)) {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.Interna_fin_de_semana
          ) {
            return false;
          }
        }
        if (filters.tipo.includes(TIPO_EXTERNA_ENTRE_SEMANA)) {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.Externa_entre_semana
          ) {
            return false;
          }
        }
        if (filters.tipo.includes(TIPO_EXTERNA_FIN_DE_SEMANA)) {
          if (
            !valoracion.candidato ||
            !valoracion.candidato.Externa_fin_de_semana
          ) {
            return false;
          }
        }

        if (filters.company === "FV") {
          if (valoracion.candidato.Company !== "FV") {
            return false;
          }
        }
        if (filters.company === "Cuideo") {
          if (valoracion.candidato.Company) {
            return false;
          }
        }

        if (filters.experience.length) {
          if (
            !valoracion.candidato ||
            !filters.experience.includes(
              valoracion.candidato.A_os_de_experiencia
            )
          ) {
            return false;
          }
        }

        if (filters.tasks.length) {
          if (
            _.difference(
              filters.tasks,
              valoracion.candidato.Qu_tareas_quieres_o_puedes_realizar
            ).length !== 0
          ) {
            return false;
          }
        }

        if (filters.languages.length) {
          if (
            _.difference(filters.languages, valoracion.candidato.Otros_Idiomas)
              .length !== 0
          ) {
            return false;
          }
        }

        if (filters.pathologies.length) {
          if (
            _.difference(
              filters.pathologies,
              valoracion.candidato.Patolog_as_en_las_que_tiene_experiencia
            ).length !== 0
          ) {
            return false;
          }
        }

        if (filters.search) {
          if (valoracion.Estado_en_proceso !== VALORACION_RECOMENDACION) {
            if (
              !hasSearchText(
                `${valoracion.candidato.Name} ${valoracion.candidato.DNI_NIE} ${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
                filters.search
              )
            ) {
              return false;
            }
          }
        }

        const availableSelected = filters.availability.includes(
          EMPLOYEE_AVAILABILITY.AVAILABLE
        );
        const workingSelected = filters.availability.includes(
          EMPLOYEE_AVAILABILITY.WORKING
        );

        const { Trabajando_Hasta } = valoracion.candidato;
        const trabajandoHastaIsNullish =
          Trabajando_Hasta === null || Trabajando_Hasta === undefined;

        if (filters.availability && availableSelected && !workingSelected) {
          const isAvailable =
            trabajandoHastaIsNullish ||
            moment(Trabajando_Hasta).isSameOrBefore(moment(), "day");
          if (!isAvailable) {
            return false;
          }
        }
        if (filters.availability && !availableSelected && workingSelected) {
          const isWorking =
            !trabajandoHastaIsNullish &&
            moment(Trabajando_Hasta).isSameOrAfter(moment(), "day");
          if (!isWorking) {
            return false;
          }
        }

        return true;
      });

      return filtered;
    };

    setSelectAll(false);
    setSelectedValoraciones([]);
    const filteredVals = filterValoraciones(valoraciones);

    const sortedVals =
      filters.orden === CONTRACT_CANDIDATE_SORT_ORDER.MORE_RECENT
        ? filteredVals.sort(sortValoracionesByRecientes)
        : filters.orden === CONTRACT_CANDIDATE_SORT_ORDER.LESS_RECENT
        ? filteredVals.sort(sortValoracionesByAntiguas)
        : filters.orden === CONTRACT_CANDIDATE_SORT_ORDER.APPROPIATED
        ? filteredVals.sort(sortValoracionesByScore)
        : filteredVals.sort(sortValoracionesByFechaFinUltimoContrato);

    if (filters.estadoEnProceso === VALUATION_STATUS.SEARCH) {
      setFilteredValoraciones(sortedVals.slice(0, MAX_CANDIDATOS_ON_MAP));
    } else {
      setFilteredValoraciones(sortedVals);
    }
  }, [valoraciones, filters]);

  const handleSingleSelection = (valoracion: any) => {
    // Check if Selection is on current page and if not we must
    // change page in order to show on the list
    const pos = filteredValoraciones
      .map((item: any) => item.id)
      .indexOf(valoracion.id);
    if (pos !== -1) {
      const newPage = Math.floor(pos / ITEMS_PER_PAGE) + 1;
      setFilters({
        ...filters,
        selectedValuationId: valoracion.id,
        page: newPage,
      });
    } else {
      setFilters({ ...filters, selectedValuationId: valoracion.id });
    }
  };

  const updateContratoAndValoraciones = async () => {
    await getContrato({ ts: Date.now() });
  };

  const handleValuationContracted = async (
    valuation: TGetContractsItemValuation,
    data: any,
    successMsg: any,
    errorMsg: any
  ) => {
    const contractedValuation = {
      ...valuation,
      Estado_en_proceso: data?.Estado_en_proceso ?? valuation.Estado_en_proceso,
    };
    setContractedValoracion({
      valuation: contractedValuation,
      successMsg: successMsg,
      errorMsg: errorMsg,
    });
    setShowContractToSelectedStateDialog(true);
  };

  const handleValuationChange: TOnContractValuationChangeFn = ({
    op,
    updatedValuation,
    refreshAfterUpdate = true,
  }) => {
    setValoraciones((prevValuations: any) => {
      if (op === "update_status_end") {
        if (updatedValuation.Estado_en_proceso === VALORACION_PRESENCIAL) {
          setToPromotePhase({
            dialogOpen: true,
            isUpdating: false,
          });
        }
      }

      if (
        filters.estadoEnProceso !== VALORACION_RECOMENDACION &&
        filters.estadoEnProceso !== VALORACION_SUGGESTION
      ) {
        if (op === "update_status_start" || op === "update_status_end") {
          const newValuations = prevValuations
            .map((item: TContractValuation) => {
              if (item.id !== updatedValuation.id) {
                return item;
              } else {
                return updatedValuation;
              }
            })
            .filter((item: TContractValuation) => {
              if (
                filters.estadoEnProceso !== VALUATION_STATUS.SEARCH &&
                item.Estado_en_proceso !== filters.estadoEnProceso
              ) {
                return false;
              }
              return true;
            });
          return newValuations;
        }
      }

      return prevValuations;
    });

    // Called when there is a change in any of the Valoracion
    if (refreshAfterUpdate) {
      updateContratoAndValoraciones();
    }
    if (selectedValoracion && updatedValuation.id === selectedValoracion.id) {
      setSelectedValoracion({});
    }

    if (filters.estadoEnProceso === VALUATION_STATUS.OUTSTANDING) {
      getValoraciones({}, true);
    }
  };

  const handleContratoChange = (modifiedContract: any) => {
    setContrato({
      ...contrato,
      ...modifiedContract,
    });
  };

  const handleCandidatoChange: TOnCandidateChangeFn = (
    op,
    changedCandidate
  ) => {
    if (op === "discard") {
      setValoraciones((prevValoraciones: any) => {
        // if (
        //   changedCandidate.status &&
        //   changedCandidate.status === "discard_candidato"
        // ) {
        const newValoraciones = prevValoraciones.map((item: any) => {
          if (item.candidato && item.candidato.id === changedCandidate.id) {
            const newItem = Object.assign({}, item);
            newItem.candidato.Estado_Cuideo = EMPLOYEE_STATUS.DISCARDED;
            return newItem;
          } else {
            return item;
          }
        });
        return newValoraciones;
        // }
      });
    } else if (op === "fav") {
      if (filters.selectedValuationId) {
        const foundValuation = valoraciones.find(
          (val: any) => val.id === filters.selectedValuationId
        );
        if (
          foundValuation &&
          foundValuation.candidato &&
          foundValuation.candidato.id
        ) {
          getCandidato(foundValuation.candidato.id);
        }
      }
    }

    // Called when there is a change in any of the Valoracion
    getContrato({ ts: Date.now() });
    getValoraciones({}, true);
  };

  const handleEmployeeProfileChange = () => {
    getContrato({ ts: Date.now() });
    // getValoraciones({}, true);
  };

  const handleMultiSelectChange = useCallback(async () => {
    const contract = await getContrato({ ts: Date.now() });
    getValoraciones({}, true, contract);
    setSelectedValoraciones([]);
  }, [getContrato, getValoraciones, setSelectedValoraciones]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedValoraciones([]);
    } else {
      setSelectAll(true);
      setSelectedValoraciones([...filteredValoraciones]);
    }
  };

  const changeDest = (nuevoEstado: TValuationStatus) => {
    if (filters.estadoEnProceso === nuevoEstado) {
      return;
    }

    setSelectedValoracion(null);
    setSelectedValoraciones([]);

    if (
      filters.estadoEnProceso === VALUATION_STATUS.SEARCH ||
      filters.estadoEnProceso === VALUATION_STATUS.OUTSTANDING
    ) {
      if (nuevoEstado !== filters.estadoEnProceso) {
        setFilters({
          ...initialState,
          selectedValuationId: "",
          estadoEnProceso: nuevoEstado,
          page: 1,
          ready: true,
        });
      }
    } else if (filters.estadoEnProceso === VALORACION_DESCARTADO) {
      if (
        nuevoEstado === VALUATION_STATUS.SEARCH ||
        nuevoEstado === VALUATION_STATUS.OUTSTANDING
      ) {
        setFilters({
          ...resetStateSuggestions,
          selectedValuationId: "",
          estadoEnProceso: nuevoEstado,
          page: 1,
          ready: true,
        });
      } else {
        setFilters({
          ...initialState,
          selectedValuationId: "",
          estadoEnProceso: nuevoEstado,
          page: 1,
          ready: true,
        });
      }
    } else {
      if (
        nuevoEstado === VALUATION_STATUS.SEARCH ||
        nuevoEstado === VALUATION_STATUS.OUTSTANDING
      ) {
        setFilters({
          ...resetStateSuggestions,
          selectedValuationId: "",
          estadoEnProceso: nuevoEstado,
          page: 1,
          ready: true,
        });
      } else {
        setFilters({
          ...filters,
          selectedValuationId: "",
          estadoEnProceso: nuevoEstado,
          page: 1,
          ready: true,
        });
      }
    }
  };

  const handleDestacadosClick = () => {
    setSelectedValoracion(null);
    setSelectedValoraciones([]);

    const DAYS_BEFORE_CONTRACT_CV_CLIENT_DATE = 3;
    const goalDate = moment(contrato.Fecha_cv_cliente)
      .subtract(DAYS_BEFORE_CONTRACT_CV_CLIENT_DATE, "days")
      .toDate();
    setFilters({
      ...filters,
      estadoEnProceso: VALUATION_STATUS.OUTSTANDING,
      estado: [EMPLOYEE_STATUS.VALIDATED_PLUS],
      orden: CONTRACT_CANDIDATE_SORT_ORDER.LAST_CONTRACT_END_DATE,
      applyByGoalDate: goalDate.toISOString(),
      inOpenProcess: "",
      botAvailable: "",
      availability: [],
    });

    getValoraciones({}, true);
  };

  const handleEstadosTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    if (newValue === 0) {
      changeDest(VALUATION_STATUS.NOT_VALUED);
    }
    if (newValue === 1) {
      changeDest(VALUATION_STATUS.PHONE);
    }
    if (newValue === 2) {
      changeDest(VALUATION_STATUS.FACE_TO_FACE);
    }
    if (newValue === 3) {
      changeDest(VALUATION_STATUS.CV_CLIENT);
    }
    if (newValue === 4) {
      changeDest(VALUATION_STATUS.FACE_TO_FACE_CLIENT);
    }
    if (newValue === 5) {
      changeDest(VALUATION_STATUS.CONTRACTED);
    }
  };

  const handleEstadoChange = (newEstado: TValuationStatus) => {
    if (filters.estadoEnProceso === newEstado) {
      return;
    }

    changeDest(newEstado);
    setSelectedValoracion(null);
    setValoraciones([]);
  };

  const handlePageChange = (event: any, value: any) => {
    const newFilters = { ...filters, page: value };
    setSelectedValoraciones([]); // 40 ms
    // 150 ms here!!!!
    setFilters(newFilters);
  };

  const handleValoracionMultiSelect = (valoracion: any) => {
    if (
      selectedValoraciones.filter((selVal: any) => selVal.id === valoracion.id)
        .length
    ) {
      const filtered = selectedValoraciones.filter(
        (val: any) => val.id !== valoracion.id
      );
      setSelectedValoraciones([...filtered]);
    } else {
      setSelectedValoraciones([...selectedValoraciones, valoracion]);
    }
  };

  const handleUnselectAll = useCallback(() => {
    setSelectedValoraciones([]);
  }, [setSelectedValoraciones]);

  const handleValoracionHover = (valoracion: any) => {
    setHoveredValoracion(valoracion);
  };

  const handleValoracionSelect = (valoracion: any) => {
    handleSingleSelection(valoracion);
  };

  const handleMapChange = (changedValues: any) => {
    if (changedValues.bounds) {
      setMap({ ...changedValues });
    } else {
      setMap({ ...map, ...changedValues });
    }
  };

  const handleTabChange = useCallback(
    (newValue: number) => {
      const newFilters = { ...filters, eTab: newValue };
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  const handleContractLocationChange = useCallback(
    (lat: number, lng: number, label: string, errorMargin: number) => {
      const locationChangeRequest = async (
        lat: number,
        lng: number,
        label: string,
        errorMargin: number
      ) => {
        try {
          await apiEmpleo.patch(
            `contratos/${contrato.id}`,
            {
              Latitud: lat + "",
              Longitud: lng + "",
              formatted_address: label,
              location_error_margin: errorMargin + "",
            },
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          );
        } catch (e) {
          modalShow({
            open: true,
            type: "error",
            message:
              "Ha habido algún error al intentar establecer la nueva ubicación",
          });
        }
      };

      locationChangeRequest(lat, lng, label, errorMargin);
      setContrato({
        ...contrato,
        Latitud: lat,
        Longitud: lng,
        formatted_address: label,
        location_error_margin: errorMargin,
      });
    },
    [contrato, modalShow]
  );

  const handlePromoteDialogCancel = () => {
    setToPromotePhase({
      dialogOpen: false,
      isUpdating: false,
    });
  };

  const handlePromoteDialogAccept = async () => {
    setToPromotePhase({
      dialogOpen: true,
      isUpdating: true,
    });
    await apiUpdateContractPhase(contrato.id, CONTRACT_PHASE.PROMOTE);
    history.push("/contratos");
  };

  const handleContractPhaseUpdate = async (payload: TUpdateContractPhase) => {
    setContrato((prevContract: any) => {
      return {
        ...prevContract,
        _uiMutation:
          payload.phase === "promote"
            ? "to_promote"
            : payload.phase === "recruit"
            ? "to_recruit"
            : "to_shared",
      };
    });

    try {
      await apiUpdateContractPhase(payload.id, payload.phase);

      getContrato({ ts: Date.now() });
    } catch (e) {
      toast.error(
        formatMessage(
          {
            id: "ContractCard.Ha habido un error al intentar modificar el {contractName}",
            defaultMessage:
              "Ha habido un error al intentar modificar el {contractName}",
          },
          {
            contractName: payload.contractName ?? "Contrato",
          }
        )
      );
      return;
    }

    if (payload.phase === "recruit") {
      toast.success(
        formatMessage(
          {
            id: "ContractCard.El {contractName} se ha envíado al Recruiter",
            defaultMessage: "El {contractName} se ha enviado al Recruiter",
          },
          {
            contractName: payload.contractName ?? "Contrato",
          }
        )
      );
    }

    if (payload.phase === "recruit_and_promote") {
      toast.success(
        formatMessage(
          {
            id: "ContractCard.El {contractName} se ha compartido",
            defaultMessage: "El {contractName} se ha compartido",
          },
          {
            contractName: payload.contractName ?? "Contrato",
          }
        )
      );
    }
  };

  const handleContractToSelectedStateDialogAccept = async (payload: any) => {
    setIsContractToSelectedStateDialogRequestLoading(true);

    try {
      await apiUpdateValuationState(contractedValoracion.valuation);

      try {
        await apiUpdateContractToSelectedState(contrato.id, payload);

        if (isContractOriginWarrantyZeroOrChangeEmployer(contrato)) {
          // Call to CoreV3 to send alert
          await apiCoreV3ContractSendAlertNotes(contrato.zohoId, {
            notes: payload.Notas_Asesora_Seleccion ?? null,
          });
        }

        updateContratoAndValoraciones();
      } catch (e) {
        modalShow({
          open: true,
          type: "error",
          message:
            "Ha habido algún error al intentar cambiar el contrato a estado seleccionado",
        });
      }
    } catch (e) {
      modalShow({
        open: true,
        type: "error",
        message: contractedValoracion.errorMsg,
      });
    }

    setIsContractToSelectedStateDialogRequestLoading(false);
    setShowContractToSelectedStateDialog(false);
  };

  const handelContractToSelectedStateDialogCancel = () => {
    setShowContractToSelectedStateDialog(false);
  };

  const selectedCandidato =
    selectedValoracion && selectedValoracion.candidato
      ? candidatos.find((item: any) => {
          if (item.id === selectedValoracion.candidato.id) {
            return item;
          }
          return false;
        })
      : null;

  const pagedValoraciones = filteredValoraciones.slice(
    (filters.page - 1) * ITEMS_PER_PAGE,
    filters.page * ITEMS_PER_PAGE
  );

  if (!filters.ready) {
    return null;
  }

  const numAdvancedValuations = !!contrato
    ? contrato.numTelefonica +
      contrato.numPresencial +
      contrato.numCVCliente +
      contrato.numPresencialCliente +
      contrato.numContratado
    : 0;

  const canAdvanceMoreValuations =
    numAdvancedValuations < MAX_NUMBER_ADVANCED_VALUATIONS;

  return (
    <StyledPageContainer appMarginTop={0}>
      {contractNotFound && (
        <Error404
          title={
            <FormattedMessage
              id="ContractPage.ContractNotFound"
              defaultMessage="El contrato que buscas no existe"
            />
          }
          message={
            <FormattedMessage
              id="ContractPage.ContractNotFoundText"
              defaultMessage="El contrato al que estás intentando acceder parece que no existe. Puede que hayas introducido la dirección mal o que el contrato haya sido borrado"
            />
          }
        />
      )}
      {contrato && contrato.Name && (
        <motion.div initial="exit" animate="enter" variants={headerVariants}>
          <ContractHeader
            contract={contrato}
            estadoEnProceso={
              filters.estadoEnProceso
                ? filters.estadoEnProceso
                : VALUATION_STATUS.NOT_VALUED
            }
            setEstadoFilter={handleEstadoChange}
            onChange={handleContratoChange}
            canAddEmployees={!contractBlocked}
            onContractPhaseUpdate={handleContractPhaseUpdate}
          />
          {filters.estadoEnProceso !== VALUATION_STATUS.DISCARDED &&
            filters.estadoEnProceso !== VALUATION_STATUS.SUGGESTION &&
            filters.estadoEnProceso !== VALUATION_STATUS.SEARCH && (
              <StyledBox display="flex">
                <StyledDestacadosButton
                  onClick={handleDestacadosClick}
                  className={isOutstandingSelected ? "selected" : ""}
                >
                  <StyledStarIcon fontSize="small" />
                  &nbsp;Destacados
                </StyledDestacadosButton>
                <StyledEstadosTabs
                  value={
                    filters.estadoEnProceso === VALUATION_STATUS.NOT_VALUED
                      ? 0
                      : filters.estadoEnProceso === VALUATION_STATUS.PHONE
                      ? 1
                      : filters.estadoEnProceso ===
                        VALUATION_STATUS.FACE_TO_FACE
                      ? 2
                      : filters.estadoEnProceso === VALUATION_STATUS.CV_CLIENT
                      ? 3
                      : filters.estadoEnProceso ===
                        VALUATION_STATUS.FACE_TO_FACE_CLIENT
                      ? 4
                      : filters.estadoEnProceso === VALUATION_STATUS.CONTRACTED
                      ? 5
                      : null
                  }
                  textColor="primary"
                  onChange={handleEstadosTabChange}
                  variant="fullWidth"
                >
                  <StyledEstadoTab
                    label={
                      <FormattedMessage
                        id="ContractPage.SinValorar"
                        defaultMessage="{numSinValorar} Sin valorar"
                        values={{
                          numSinValorar: (
                            <span className="MuiTab-CuideoNumber">
                              {contrato.numSinValorar}
                            </span>
                          ),
                        }}
                      />
                    }
                    disabled={contrato.numSinValorar === 0}
                  />
                  <StyledEstadoTab
                    label={
                      <FormattedMessage
                        id="ContractPage.Telefónica"
                        defaultMessage="{numTelefonica} Telefónica"
                        values={{
                          numTelefonica: (
                            <span className="MuiTab-CuideoNumber">
                              {contrato.numTelefonica}
                            </span>
                          ),
                        }}
                      />
                    }
                    disabled={contrato.numTelefonica === 0}
                  />
                  <StyledEstadoTab
                    label={
                      <FormattedMessage
                        id="ContractPage.Presencial"
                        defaultMessage="{numPresencial} Presencial"
                        values={{
                          numPresencial: (
                            <span className="MuiTab-CuideoNumber">
                              {contrato.numPresencial}
                            </span>
                          ),
                        }}
                      />
                    }
                    disabled={contrato.numPresencial === 0}
                  />
                  <StyledEstadoTab
                    label={
                      <FormattedMessage
                        id="ContractPage.CVaCliente"
                        defaultMessage="{numCVCliente} CV Cliente"
                        values={{
                          numCVCliente: (
                            <span className="MuiTab-CuideoNumber">
                              {contrato.numCVCliente}
                            </span>
                          ),
                        }}
                      />
                    }
                    disabled={contrato.numCVCliente === 0}
                  />
                  <StyledEstadoTab
                    label={
                      <FormattedMessage
                        id="ContractPage.PresencialCliente"
                        defaultMessage="{numPresencialCliente} Presencial Cliente"
                        values={{
                          numPresencialCliente: (
                            <span className="MuiTab-CuideoNumber">
                              {contrato.numPresencialCliente}
                            </span>
                          ),
                        }}
                      />
                    }
                    disabled={contrato.numPresencialCliente === 0}
                  />
                  <StyledEstadoTab
                    label={
                      <FormattedMessage
                        id="ContractPage.Contratado"
                        defaultMessage="{numContratado} Seleccionad@"
                        values={{
                          numContratado: (
                            <span className="MuiTab-CuideoNumber">
                              {contrato.numContratado}
                            </span>
                          ),
                        }}
                      />
                    }
                    disabled={contrato.numContratado === 0}
                  />
                </StyledEstadosTabs>
              </StyledBox>
            )}
          <StyledFiltersWrapper>
            <ContratoFilters
              showViewOptions={!!selectedCandidato}
              showSourceOptions={
                filters.estadoEnProceso !== VALUATION_STATUS.SEARCH
              }
              filters={filters}
              setFilters={setFilters}
              // isLoading={!!loadingValoraciones}
            />
          </StyledFiltersWrapper>
        </motion.div>
      )}
      {contrato && (
        <VariableContainer
          key={`vcont-${contrato.id}`}
          hash={filters.estadoEnProceso}
        >
          <StyledListPane
            style={{
              width:
                selectedCandidato && filters.vista === "lista"
                  ? "28rem"
                  : !selectedCandidato
                  ? "28rem"
                  : "0",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: "0 1 100%",
                  overflow: "hidden",
                }}
              >
                <motion.div
                  initial="exit"
                  animate={
                    !selectedCandidato || filters.vista === "lista"
                      ? "enter"
                      : "exit"
                  }
                  style={{
                    height: "100%",
                    zIndex: filters.vista === "lista" ? 1 : 0,
                  }}
                  variants={valuationsVariants}
                >
                  <ValoracionesList
                    isLoading={loadingValoraciones}
                    valoraciones={pagedValoraciones}
                    totalValoraciones={filteredValoraciones.length}
                    selectedValoracion={selectedValoracion}
                    selectedValoraciones={selectedValoraciones}
                    selectedAll={selectAll}
                    onToggleSelectAll={handleSelectAllChange}
                    onValoracionSelect={handleSingleSelection}
                    onValuationChange={handleValuationChange}
                    onToggleValoracionMultiSelect={handleValoracionMultiSelect}
                    page={filters.page}
                    onPageChange={handlePageChange}
                    itemsPerPage={ITEMS_PER_PAGE}
                    hoveredValoracion={hoveredValoracion}
                    onValoracionHover={setHoveredValoracion}
                    valoracionesBlocked={contractBlocked}
                    addMode={
                      filters.estadoEnProceso === VALUATION_STATUS.SEARCH
                    }
                    // userPermissions={permissions}
                    canAdvanceMoreValuations={canAdvanceMoreValuations}
                    isOutstanding={outstandingIsSelected(filters)}
                    onValuationContracted={handleValuationContracted}
                  />
                </motion.div>
              </div>
              {permissions.canConverseInContract && (
                <Conversation contractId={match.params.contratoId} />
              )}
            </div>
          </StyledListPane>

          <StyledMapPane
            style={{
              width:
                selectedCandidato && filters.vista === "mapa"
                  ? "28rem"
                  : selectedCandidato && filters.vista === "lista"
                  ? "0"
                  : "calc(100% - 28rem)",
            }}
          >
            <motion.div
              initial="exit"
              animate="enter"
              variants={mapVariants}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: filters.vista === "mapa" ? 1 : 0,
              }}
            >
              <ContratoValoracionesMap
                valoraciones={filteredValoraciones}
                contrato={contrato}
                selectedValoracion={selectedValoracion}
                hoveredValoracion={hoveredValoracion}
                onValoracionHover={handleValoracionHover}
                onValoracionSelect={handleValoracionSelect}
                map={map}
                onMapChange={handleMapChange}
                showMaxWarning={
                  filters.estadoEnProceso === VALUATION_STATUS.SEARCH &&
                  !selectedValoracion &&
                  filteredValoraciones.length >= MAX_CANDIDATOS_ON_MAP
                }
                showLoading={!!loadingValoraciones}
                allowMapSearch
                onContractLocationChange={handleContractLocationChange}
              />
            </motion.div>
          </StyledMapPane>

          <StyledCandidatoPane
            className="CMuiCandidatePane"
            style={{
              width: !selectedCandidato ? "0" : "calc(100% - 28rem)",
            }}
          >
            <StyledCandidatoContainer className="CMuiCandidateContainer">
              {selectedValoracion &&
                selectedValoracion.candidato &&
                selectedValoracion.candidato.Apellidos && (
                  <CandidatoFullProfile
                    candidato={
                      selectedCandidato &&
                      selectedCandidato.id === selectedValoracion.candidato.id
                        ? selectedCandidato
                        : selectedValoracion.candidato
                    }
                    valoracion={selectedValoracion}
                    onUnselect={() => {
                      setFilters({ ...filters, selectedValuationId: "" });
                      setSelectedValoracion(null);
                    }}
                    onChange={handleCandidatoChange}
                    tab={filters.eTab}
                    onTabChange={handleTabChange}
                    onEmployeeProfileChange={handleEmployeeProfileChange}
                    context={{
                      candidate_valuation_crm_id: selectedValoracion.zohoId,
                      contract_crm_id: contrato.zohoId,
                    }}
                    currentContract={contrato}
                  />
                )}
            </StyledCandidatoContainer>
          </StyledCandidatoPane>
        </VariableContainer>
      )}
      <ValoracionesMultiSelectFooter
        onChange={handleMultiSelectChange}
        valoraciones={selectedValoraciones}
        onUnselectAll={handleUnselectAll}
        currentContract={contrato}
      />
      {contrato && permissions.canPromoteContract && (
        <ToPromotePhaseDialog
          open={toPromotePhase.dialogOpen}
          isUpdating={toPromotePhase.isUpdating}
          onAccept={handlePromoteDialogAccept}
          onCancel={handlePromoteDialogCancel}
          contractCrmIdName={contrato.Name ?? "Contrato"}
        />
      )}
      {!!contrato && permissions.canHandlePromotePhaseValuations && (
        <CvFollowUpDialog
          open={cvFollowUpState.mustDoFollowUp}
          status={cvFollowUpState.status}
          selectedFollowUp={cvFollowUpState.selectedFollowUp}
          onCallClient={callClient}
          onNotAnsweredSendEmail={noAnswerSendMail}
          onSendEmail={sendMail}
          onAlreadyTalkedWithClient={alreadyTalkedWithClient}
          onFollowUpDone={confirmFollowUp}
        />
      )}
      {contrato && (
        <ContractToSelectedStateDialog
          open={showContractToSelectedStateDialog}
          isRequestLoading={isContractToSelectedStateDialogRequestLoading}
          contract={contrato}
          onAccept={(values) =>
            handleContractToSelectedStateDialogAccept(values)
          }
          onCancel={handelContractToSelectedStateDialogCancel}
        />
      )}
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  candidatosState,
  auth,
}: {
  candidatosState: any;
  auth: any;
}) => {
  const { candidatos } = candidatosState;
  const { authUser } = auth;
  return { candidatos, authUser };
};

export default connect(mapStateToProps, {
  setBackground,
  push,
  getCandidato,
  modalShow,
  snackSuccessMessage,
  filtersSetContrato,
  filtersResetContrato,
  filtersResetContratoSuggestions,
})(ContractPage);
