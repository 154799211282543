import { TContractsTableColumns } from "./ContractsTable.types";

export const defaultTableColumnsForRecruiterView: TContractsTableColumns = [
  "Name",
  "Estado",
  //"createdAt",
  "Tipo_de_Trato",
  "ownerName",
  "Fecha_objetivo",
  "Fecha_cv_cliente",
  "Squad",
  "Servicio.ownerName",
  "Servicio.ReferenteB2B.Name",
];

export const defaultTableColumnsForAllContractsView: TContractsTableColumns = [
  "Name",
  "Estado",
  //"createdAt",
  "Tipo_de_Trato",
  "ownerName",
  "Fecha_objetivo",
  "Fecha_cv_cliente",
  "Provincia_servicio",
  "Squad",
  "Servicio.ownerName",
  "Servicio.ReferenteB2B.Name",
];
