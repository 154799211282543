import moment from "moment";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { EMPLOYEE_WORK_REGIME } from "../../../../../../api/employee/employee.types";
import useFormatters from "../../../../../../components/FormattedFields/useFormatters";
import { getNivelFormativoLabel } from "../../../../../../util/defaults/education";
import { prepareTitulacionesInitialValue } from "../../../../../../util/defaults/titulaciones";
import { formatAsPhoneNumber } from "../../../../../../util/formatters";
import { TCoordinatorFileESOptions } from "./useCoordinatorFileESFormOptions";

export default function useCoordinatorFileESInitialValues(
  candidato: any,
  formOptions: TCoordinatorFileESOptions
) {
  const { formatMessage } = useIntl();
  const { formatExperience, formatCountry, formatLanguage } = useFormatters();

  const initialValues = useMemo(() => {
    const noBotDate = moment(candidato.No_iniciar_bot_hasta);
    let noBotText = "";
    let noBotFlag = false;
    if (candidato.No_iniciar_bot_hasta && noBotDate.isValid()) {
      noBotFlag = true;
      const nowPlus50 = moment().add(50, "years");
      if (noBotDate.isBefore(nowPlus50)) {
        noBotText = noBotDate.format("YYYY-MM-DD");
      }
    }

    const tempInitialValues = {
      Nombre: candidato.Nombre,
      Apellidos: candidato.Apellidos,
      Apellidos_2: candidato.Apellidos_2 ? candidato.Apellidos_2 : "",
      DNI_NIE: candidato.DNI_NIE,
      N_CUENTA: candidato.N_CUENTA,
      Sexo: candidato.Sexo,
      Fecha_de_nacimiento: candidato.Fecha_de_nacimiento
        ? moment(candidato.Fecha_de_nacimiento).toDate()
        : null,
      Trabajando_Hasta: candidato.Trabajando_Hasta
        ? moment(candidato.Trabajando_Hasta).toDate()
        : undefined,
      substitutions: candidato.substitutions,
      Vencimiento_NIE: candidato.Vencimiento_NIE
        ? moment(candidato.Vencimiento_NIE).toDate()
        : null,
      Lugar_de_nacimiento: {
        value: candidato.Lugar_de_nacimiento,
        label: formatCountry(candidato.Lugar_de_nacimiento),
      },
      Otros_Idiomas: candidato.Otros_Idiomas.map((item: any) => {
        return {
          value: item,
          label: formatLanguage(item),
        };
      }),
      Nivel_formativo: {
        value: candidato.Nivel_formativo,
        label: getNivelFormativoLabel(candidato.Nivel_formativo),
      },
      A_os_de_experiencia: candidato.A_os_de_experiencia
        ? {
            value: candidato.A_os_de_experiencia,
            label: formatExperience(candidato.A_os_de_experiencia),
          }
        : null,
      Direccion: candidato.Direccion,
      autocompletedDireccion: candidato.Direccion,
      Numero: candidato.Numero,
      autocompletedNumero: candidato.Numero,
      Piso: candidato.Piso,
      Codigo_postal: candidato.Codigo_postal,
      autocompletedCodigoPostal: candidato.Codigo_postal,
      Phone: formatAsPhoneNumber(candidato.Phone),
      Email: candidato.Email,
      Ciudad: candidato.Ciudad
        ? {
            value: candidato.Ciudad,
            label: candidato.Ciudad,
          }
        : null,
      autocompletedCiudad: candidato.Ciudad,
      Provincia: {
        value: candidato.Provincia,
        label: candidato.Provincia,
      },
      Carnet_de_conducir: candidato.Carnet_de_conducir,
      Veh_culo_propio: candidato.Veh_culo_propio,
      Titulaci_n_oficial_SAD: prepareTitulacionesInitialValue(
        candidato.Titulaci_n_oficial_SAD
      ),
      Interna_entre_semana: candidato.Interna_entre_semana,
      Interna_fin_de_semana: candidato.Interna_fin_de_semana,
      Externa_entre_semana: candidato.Externa_entre_semana,
      Externa_fin_de_semana: candidato.Externa_fin_de_semana,
      REEH:
        candidato.R_gimen_de_trabajo &&
        Array.isArray(candidato.R_gimen_de_trabajo)
          ? candidato.R_gimen_de_trabajo.indexOf(EMPLOYEE_WORK_REGIME.REEH) !==
            -1
          : false,
      SAD:
        candidato.R_gimen_de_trabajo &&
        Array.isArray(candidato.R_gimen_de_trabajo)
          ? candidato.R_gimen_de_trabajo.indexOf(EMPLOYEE_WORK_REGIME.SAD) !==
            -1
          : false,
      Observaciones_generales: candidato.Observaciones_generales,
      Notas_disponibilidad: candidato.Notas_disponibilidad,
      N_SS: candidato.N_SS,
      W_Breve_descripci_n: candidato.W_Breve_descripci_n,
      Formaciones: candidato.Formaciones,
      Experiencia_y_referencias: candidato.Experiencia_y_referencias,
      Latitud: candidato.Latitud,
      Longitud: candidato.Longitud,
      cif_obverse:
        candidato.obverseCif && candidato.obverseCif.fileUrl
          ? `candidatos/${candidato.id}/obverse-cif/view`
          : null,
      cif_back:
        candidato.backCif && candidato.backCif.fileUrl
          ? `candidatos/${candidato.id}/back-cif/view`
          : null,
      curriculum:
        candidato.curriculum && candidato.curriculum.fileUrl
          ? {
              fileUrl: candidato.curriculum.fileUrl,
              fileView: `candidatos/${candidato.id}/curriculum/view`,
            }
          : null,
      No_mostrar_imagen: candidato.No_mostrar_imagen ? true : false,
      No_iniciar_bot_hasta: noBotText,
      noBot: noBotFlag,
      hasWhatsapp: candidato.hasWhatsapp,
    } as any;
    formOptions.taskOptions.forEach((item: any) => {
      tempInitialValues["tarea__" + item.value] =
        candidato.Qu_tareas_quieres_o_puedes_realizar.includes(item.value);
    });
    formOptions.pathologyOptions.forEach((item: any) => {
      tempInitialValues["pathology__" + item.value] =
        candidato.Patolog_as_en_las_que_tiene_experiencia.includes(item.value);
    });
    formOptions.timeSlotOptions.forEach((item: any) => {
      tempInitialValues["timeSlot__" + item.value] =
        candidato.Franja_horaria.includes(item.value);
    });

    return tempInitialValues;
    // eslint-disable-next-line
  }, [candidato, formatMessage]);

  return initialValues;
}
