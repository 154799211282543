import { FC } from "react";
import cuideoIcon from "../../../../../../assets/images/iconos/cuideoIso.png";
import { useGetCorev3Contracts } from "../../api/useGetCorev3ContractByCrmId";
import {
  StyledActionButton,
  StyledActionIcon,
} from "../ContractCard/ContractCardSC";
import { Box, Tooltip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {
  StyledActionLink,
  StyledActionLinkText,
} from "../ContractHeader/ContractHeader.SC";

export const ManagerLink: FC<{
  zohoId: string | undefined;
  type: "button" | "link";
}> = ({ zohoId, type }) => {
  const corev3ContractQuery = useGetCorev3Contracts(zohoId);

  return corev3ContractQuery.data?.customer_id ? (
    <>
      {type === "button" ? (
        <Tooltip
          title={
            <FormattedMessage
              id="ManagerLink.ManagerTooltip"
              defaultMessage="Ver en el Manager"
            />
          }
        >
          <StyledActionButton
            color="primary"
            onClick={() => {
              window.open(
                `${
                  process.env.REACT_APP_ENV &&
                  process.env.REACT_APP_ENV === "pre"
                    ? "https://manager.pre.cuideo.com"
                    : "https://manager.cuideo.com"
                }/customers/${corev3ContractQuery.data.customer_id}`
              );
            }}
          >
            <StyledActionIcon src={cuideoIcon} />
          </StyledActionButton>
        </Tooltip>
      ) : (
        <StyledActionLink
          color="primary"
          onClick={() => {
            window.open(
              `${
                process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "pre"
                  ? "https://manager.pre.cuideo.com"
                  : "https://manager.cuideo.com"
              }/customers/${corev3ContractQuery.data.customer_id}`
            );
          }}
        >
          <StyledActionIcon src={cuideoIcon} />
          <StyledActionLinkText>
            <Box ml={5}>
              <FormattedMessage
                id="ManagerLink.ViewInManager"
                defaultMessage="Ver en el Manager"
              />
            </Box>
          </StyledActionLinkText>
        </StyledActionLink>
      )}
    </>
  ) : null;
};
