import { TableCell, TableRow, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { COLOR_RED_NOTIFICATION } from "../../../../../../containers/themes/defaultTheme";
import {
  ContractPhaseStepper,
  ContractPipelineStepper,
  TContractPartialWithNumbers,
} from "../../../contract";
import { ColoredAvatar } from "../../../user";
import {
  showCvWarning,
  showFaceToFaceWarning,
} from "../../util/contract-pipeline.util";
import { TContractsTableColumns } from "./ContractsTable.types";

const ContractNameTableCell = styled(TableCell)`
  padding: 0.2rem 0.75rem !important;
`;

const StyledContractName = styled(Typography)`
  font-size: 0.875rem;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 600;
`;

const StyledContractPlan = styled(Typography)`
  font-size: 0.635rem;
  font-family: "Poppins", Arial, sans-serif;
`;

const StyledContractStatus = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;

  &.CMui-warning {
    color: #ebc200;
  }

  &.CMui-error {
    color: ${COLOR_RED_NOTIFICATION};
  }
`;

const StyledPipelineWrapper = styled.div`
  margin: 0.125rem 0;

  .CMuiContractPhaseStepper {
    height: 0.75rem;
    gap: 0.75rem;

    .CMuiStep__body {
      padding: 0;
    }
  }
`;

const StyledPhaseWrapper = styled.div`
  margin: 0.125rem 0;

  .CMuiContractPhaseStepper {
    height: 0.375rem;
  }
`;

const StyledPromoterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  .MuiAvatar-root {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.125rem;
  }
`;

const StyledPromoterFullName = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const StyledB2B = styled(Typography)`
  font-size: 0.9375rem;
  width: 100%;
  height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

interface IContractRowProps {
  index: number;
  style: any;
  data: {
    columns: TContractsTableColumns;
    items: TContractPartialWithNumbers[];
    isLoading?: boolean;
    currentTime?: Date;
    selectedContractId?: string | null;
    onSelectedContractChange: (contractId: string) => void;
  };
}

const ContractRow: React.FC<IContractRowProps> = ({
  index,
  style,
  data: {
    columns,
    items,
    isLoading,
    currentTime = new Date(),
    selectedContractId,
    onSelectedContractChange,
  },
}) => {
  const contract = items[index];

  const handleClick = () => {
    onSelectedContractChange(contract.id);
  };

  return (
    <TableRow
      component="div"
      className={classNames({
        "CMui-selected": contract.id === selectedContractId,
      })}
      style={{ ...style, display: "flex" }}
      onClick={handleClick}
    >
      {columns.map((column) => {
        if (column === "Name") {
          return (
            <ContractNameTableCell
              key={`ContractRow__Name__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              <StyledContractName>{contract.Name}</StyledContractName>
              <StyledContractPlan>
                {contract.Servicio?.Plan ?? "-"}
              </StyledContractPlan>
            </ContractNameTableCell>
          );
        }

        if (column === "Estado") {
          return (
            <TableCell
              key={`ContractRow__Estado__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              <StyledContractStatus
                className={classNames({
                  // "CMui-warning":
                  //   showFaceToFaceWarning(contract, currentTime) ||
                  //   showCvWarning(contract),
                  "CMui-error": showCvWarning(contract),
                  "CMui-success":
                    typeof contract.numContracted !== "undefined" &&
                    contract.numContracted > 0,
                })}
              >
                {contract.Estado}
              </StyledContractStatus>
            </TableCell>
          );
        }

        if (column === "pipeline") {
          return (
            <TableCell
              key={`ContractRow__pipeline__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "5rem", maxWidth: "5rem", flex: "0 0 0" }}
            >
              <StyledPipelineWrapper>
                <ContractPipelineStepper
                  disableLabels
                  isLoading={isLoading}
                  numWithoutVal={contract.numWithoutVal}
                  numPhone={contract.numPhone}
                  numFaceToFace={contract.numFaceToFace}
                  numCvClient={contract.numCvClient}
                  numFaceToFaceClient={contract.numFaceToFaceClient}
                  numContracted={contract.numContracted}
                  warningOnCv={showCvWarning(contract)}
                  warningFaceToFace={showFaceToFaceWarning(
                    contract,
                    currentTime
                  )}
                />
              </StyledPipelineWrapper>
              {contract.ownerName !== contract.Servicio?.ownerName && (
                <StyledPhaseWrapper>
                  <ContractPhaseStepper phase={contract.phase} disableLabels />
                </StyledPhaseWrapper>
              )}
            </TableCell>
          );
        }

        if (column === "createdAt") {
          return (
            <TableCell
              key={`ContractRow__createdAt__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "6rem", maxWidth: "6rem", flex: "0 0 0" }}
            >
              {moment(contract.createdAt).format("DD/MM/YYYY")}
            </TableCell>
          );
        }

        if (column === "Tipo_de_Trato") {
          return (
            <TableCell
              key={`ContractRow__Tipo_de_Trato__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              {contract.Tipo_de_Trato ?? "-"}
            </TableCell>
          );
        }

        if (column === "Fecha_objetivo") {
          return (
            <TableCell
              key={`ContractRow__Fecha_objetivo__${contract.id}`}
              className={classNames({
                "CMui-error": moment(contract.Fecha_objetivo).isBefore(
                  moment(currentTime)
                ),
              })}
              component="div"
              variant="body"
              style={{ minWidth: "6rem", maxWidth: "6rem", flex: "0 0 0" }}
            >
              {moment(contract.Fecha_objetivo).format("DD/MM/YYYY")}
            </TableCell>
          );
        }

        if (column === "Fecha_cv_cliente") {
          return (
            <TableCell
              key={`ContractRow__Fecha_cv_cliente__${contract.id}`}
              className={classNames({
                "CMui-error": contract.Fecha_cv_cliente
                  ? moment(contract.Fecha_cv_cliente).isBefore(
                      moment(currentTime)
                    )
                  : false,
              })}
              component="div"
              variant="body"
              style={{ minWidth: "6rem", maxWidth: "6rem", flex: "0 0 0" }}
            >
              {contract.Fecha_cv_cliente
                ? moment(contract.Fecha_cv_cliente).format("DD/MM/YYYY")
                : "-"}
            </TableCell>
          );
        }

        if (column === "Provincia_servicio") {
          return (
            <TableCell
              key={`ContractRow__Provincia__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              {contract.Provincia_servicio ?? "-"}
            </TableCell>
          );
        }

        if (column === "Servicio.ownerName") {
          return (
            <TableCell
              key={`ContractRow__ServicioOwner__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "10rem", flex: "1 0 10rem" }}
            >
              <StyledPromoterWrapper>
                <ColoredAvatar fullName={contract.Servicio?.ownerName} />
                <StyledPromoterFullName>
                  {contract.Servicio?.ownerName}
                </StyledPromoterFullName>
              </StyledPromoterWrapper>
            </TableCell>
          );
        }

        if (column === "ownerName") {
          return (
            <TableCell
              key={`ContractRow__Owner__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "10rem", flex: "1 0 10rem" }}
            >
              <StyledPromoterWrapper>
                <ColoredAvatar fullName={contract.ownerName} />
                <StyledPromoterFullName>
                  {contract.ownerName}
                </StyledPromoterFullName>
              </StyledPromoterWrapper>
            </TableCell>
          );
        }

        if (column === "reason") {
          return (
            <TableCell
              key={`ContractRow__reason__${contract.id}`}
              component="div"
              variant="body"
              style={{ width: "6rem" }}
            >
              -
            </TableCell>
          );
        }

        if (column === "Servicio.ReferenteB2B.Name") {
          return (
            <TableCell
              key={`ContractRow__ServicioReferenteB2BName__${contract.id}`}
              component="div"
              variant="body"
              style={{
                minWidth: "10rem",
                flex: "1 0 10rem",
              }}
            >
              <StyledB2B>
                {contract.Servicio?.Referente_B2B?.Name ?? "-"}
              </StyledB2B>
            </TableCell>
          );
        }

        if (column === "Squad") {
          return (
            <TableCell
              key={`ContractRow__Squad__${contract.id}`}
              component="div"
              variant="body"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              {contract.Squad}
            </TableCell>
          );
        }

        return <></>;
      })}
    </TableRow>
  );
};

export default React.memo(
  (props: IContractRowProps) => {
    return <ContractRow {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.data.items !== nextProps.data.items ||
      prevProps.data.columns !== nextProps.data.columns ||
      prevProps.data.isLoading !== nextProps.data.isLoading ||
      prevProps.data.selectedContractId !== nextProps.data.selectedContractId ||
      prevProps.data.onSelectedContractChange !==
        nextProps.data.onSelectedContractChange
    ) {
      return false;
    }
    return true;
  }
);
