import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import styled from "styled-components";
import ZohoIcon from "../../../../../../assets/images/iconos/zoho.png";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import {
  ContractPhaseStepper,
  ContractPipelineStepper,
  useContractEvents,
  useContractSalaryFormatter,
  useUpdateContract,
} from "../../../contract";
import { useContractOffer } from "../../../contract/api/getContractOffer";
import ContractOwnerSelect from "../../../contract/components/ContractCard/ContractOwnerSelect";
import {
  showCvWarning,
  showFaceToFaceWarning,
} from "../../util/contract-pipeline.util";
import ContractEventsTimeline from "./ContractEventsTimeline";

const StyledWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

const StyledContractInfo = styled.div`
  margin: 1rem;
  min-height: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledContractTopActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIconButton = styled(Button)`
  width: 1.75rem;
  height: 1.75rem;
  min-height: unset;
  min-width: unset;
  border-radius: 50%;

  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const StyledActionIcon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 1rem;
  max-height: 1rem;
`;

const StyledPipelineStatusWrapper = styled.div`
  margin: 1rem;
`;

const StyledPipelineWrapper = styled.div`
  margin: 0.25rem 0;
`;

const StyledPhaseWrapper = styled.div`
  margin: 0.25rem 0;
`;

const StyledContractOwner = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.15;
  text-align: left;
  color: ${cuideoTheme.palette.primary.main};
  margin-right: 20%;
  opacity: 0.5;

  &.Mui-active {
    font-weight: 700;
    opacity: 1;
  }
`;

const StyledServiceOwner = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.15;
  text-align: right;
  color: ${cuideoTheme.palette.primary.main};
  margin-left: 20%;
  opacity: 0.5;

  &.Mui-active {
    font-weight: 700;
    opacity: 1;
  }
`;

const StyledContractName = styled(Typography)`
  font-family: "Poppins", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: 0.25rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledAccordion = styled(Accordion)`
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 72, 120, 0.15);

  &::before {
    display: none;
  }

  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.5rem 0 0;
    color: ${cuideoTheme.palette.primary.main};
  }

  .MuiAccordionSummary-root {
    min-height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
  }

  .MuiAccordionSummary-content {
    color: ${cuideoTheme.palette.primary.main};
    font-family: "Poppins", Arial, sans-serif;
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.35;
    margin: 0;

    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
      margin: 0 0.25rem 0 0;
    }
  }

  .MuiAccordionDetails-root {
    display: block;
  }

  &.Mui-expanded {
    margin: 1rem;
  }
`;

const StyledItem = styled.div`
  padding: 0.75rem 0.75rem;
  border-top: 1px solid rgba(0, 72, 120, 0.15);
  box-sizing: border-box;
  width: 100%;
`;

const StyledItemLabel = styled(Typography)`
  font-family: "Poppins", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.35;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledItemData = styled(Typography)`
  font-size: 1rem;
  line-height: 1.35;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledOwnerSelectWrapper = styled.div`
  .CMuiContractOwnerSelect {
    height: 2.25rem;
  }

  .CMuiButtonLabel {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    flex: 1 1 auto;
  }

  .CMuiButtonLabelTop {
    display: none;
  }
`;

interface IContractAsideProps {
  contractId: string;
  currentTime?: Date;
}

const ContractAside: React.FC<IContractAsideProps> = (props) => {
  const { contractId, currentTime = new Date() } = props;

  const history = useHistory();
  const contractQuery = useContractOffer({ contractId });
  const eventsQuery = useContractEvents({ contractId });
  const updateContractMutation = useUpdateContract();
  const salaryFormatter = useContractSalaryFormatter();

  const handleChangeContractOwner = (
    newOwnerId: string,
    newOwnerName: string
  ) => {
    updateContractMutation.mutate({
      contractId,
      Owner: newOwnerId,
      ownerName: newOwnerName,
    });
  };

  const viewContractUrl = `/contrato/${contractId}`;
  const viewContractInZohoUrl = `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contractQuery.data?.zohoId}`;

  const handleViewContractClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.push(viewContractUrl);
  };

  const handleViewContractInZohoClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (contractQuery.data?.zohoId) {
      window.open(
        `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contractQuery.data.zohoId}`
      );
    }
  };

  return (
    <StyledWrapper key={`ContractAside--${contractId}`}>
      <StyledContractInfo>
        <StyledContractName>
          {contractQuery.isLoading ? (
            <Skeleton width={180} />
          ) : (
            contractQuery.data?.Name
          )}
        </StyledContractName>
        <StyledContractTopActions>
          <Tooltip
            title={
              <FormattedMessage
                id="ContractAside.Ver contrato"
                defaultMessage="Ver contrato"
              />
            }
          >
            <StyledIconButton
              href={viewContractUrl}
              color="primary"
              onClick={handleViewContractClick}
            >
              {contractQuery.isLoading ? (
                <Skeleton width={20} />
              ) : (
                <VisibilityIcon />
              )}
            </StyledIconButton>
          </Tooltip>
          <Tooltip
            title={
              <FormattedMessage
                id="ContractAside.Ver en Zoho"
                defaultMessage="Ver en Zoho"
              />
            }
          >
            <StyledIconButton
              href={viewContractInZohoUrl}
              color="primary"
              onClick={handleViewContractInZohoClick}
            >
              {contractQuery.isLoading ? (
                <Skeleton width={20} />
              ) : (
                <StyledActionIcon src={ZohoIcon} />
              )}
            </StyledIconButton>
          </Tooltip>
        </StyledContractTopActions>
      </StyledContractInfo>
      <StyledPipelineStatusWrapper>
        <StyledPipelineWrapper>
          <ContractPipelineStepper
            numWithoutVal={contractQuery.data?.numWithoutVal}
            numPhone={contractQuery.data?.numPhone}
            numFaceToFace={contractQuery.data?.numFaceToFace}
            numCvClient={contractQuery.data?.numCvClient}
            numFaceToFaceClient={contractQuery.data?.numFaceToFaceClient}
            numContracted={contractQuery.data?.numContracted}
            isLoading={contractQuery.isLoading}
            warningOnCv={
              !!contractQuery.data ? showCvWarning(contractQuery.data) : false
            }
            warningFaceToFace={
              !!contractQuery.data
                ? showFaceToFaceWarning(contractQuery.data, currentTime)
                : false
            }
          />
        </StyledPipelineWrapper>
        {!!contractQuery.data &&
          contractQuery.data.ownerName !==
            contractQuery.data.Servicio?.ownerName && (
            <StyledPhaseWrapper>
              <ContractPhaseStepper
                phase={contractQuery.data?.phase}
                isLoading={contractQuery.isLoading}
              />
              <Grid container spacing={8} justifyContent="space-between">
                <Grid item xs={6}>
                  <StyledContractOwner
                    className={classNames({
                      "Mui-active": contractQuery.data?.phase === "recruit",
                    })}
                  >
                    {contractQuery.data?.ownerName}
                  </StyledContractOwner>
                </Grid>
                <Grid item xs={6}>
                  <StyledServiceOwner
                    className={classNames({
                      "Mui-active": contractQuery.data?.phase === "promote",
                    })}
                  >
                    {contractQuery.data?.Servicio?.ownerName}
                  </StyledServiceOwner>
                </Grid>
              </Grid>
            </StyledPhaseWrapper>
          )}
      </StyledPipelineStatusWrapper>
      <StyledAccordion elevation={0} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <DescriptionIcon />
          <FormattedMessage
            id="ContractAside.Detalles"
            defaultMessage="Detalles"
          />
        </AccordionSummary>
        <AccordionDetails>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Estado"
                defaultMessage="Estado"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : (
                contractQuery.data?.Estado
              )}
            </StyledItemData>
          </StyledItem>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Asignar a técnico/a de reclutamiento"
                defaultMessage="Asignar a técnico/a de reclutamiento"
              />
            </StyledItemLabel>
            <StyledOwnerSelectWrapper>
              {contractQuery.isLoading && <Skeleton height={38} />}
              {!!contractQuery.data &&
                !!contractQuery.data.ownerId &&
                !!contractQuery.data.ownerName && (
                  <ContractOwnerSelect
                    contractOwnerId={contractQuery.data.ownerId}
                    contractOwnerName={contractQuery.data.ownerName}
                    contractCrmIdName={contractQuery.data.Name}
                    onChangeContractOwner={handleChangeContractOwner}
                  />
                )}
            </StyledOwnerSelectWrapper>
          </StyledItem>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <StyledItem>
                <StyledItemLabel>
                  <FormattedMessage
                    id="ContractAside.Ciudad"
                    defaultMessage="Ciudad"
                  />
                </StyledItemLabel>
                <StyledItemData>
                  {contractQuery.isLoading ? (
                    <Skeleton />
                  ) : (
                    contractQuery.data?.Ciudad_servicio ?? "-"
                  )}
                </StyledItemData>
              </StyledItem>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledItem>
                <StyledItemLabel>
                  <FormattedMessage
                    id="ContractAside.Squad"
                    defaultMessage="Squad"
                  />
                </StyledItemLabel>
                <StyledItemData>
                  {contractQuery.isLoading ? (
                    <Skeleton />
                  ) : (
                    contractQuery.data?.Squad
                  )}
                </StyledItemData>
              </StyledItem>
            </Grid>
          </Grid>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Horario"
                defaultMessage="Horario"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : (
                contractQuery.data?.Horario_Servicio ?? "-"
              )}
            </StyledItemData>
          </StyledItem>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Salario"
                defaultMessage="Salario"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : (
                salaryFormatter(
                  contractQuery.data?.Salario_Bruto_Mensual,
                  contractQuery.data?.Tipo_de_salario
                )
              )}
            </StyledItemData>
          </StyledItem>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Delegado Comercial"
                defaultMessage="Delegado Comercial"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : (
                contractQuery.data?.Delegacion_comercial ?? "-"
              )}
            </StyledItemData>
          </StyledItem>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Servicio Express"
                defaultMessage="Servicio Express"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : contractQuery.data?.Servicio_Express ? (
                "Sí"
              ) : (
                "No"
              )}
            </StyledItemData>
          </StyledItem>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Zona sin base"
                defaultMessage="Zona sin base"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : contractQuery.data?.Servicio?.Zona_sin_base ? (
                "Sí"
              ) : (
                "No"
              )}
            </StyledItemData>
          </StyledItem>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Cliente nuevo"
                defaultMessage="Cliente nuevo"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : contractQuery.data?.Origen === "Venta" ? (
                "Sí"
              ) : (
                "No"
              )}
            </StyledItemData>
          </StyledItem>
          <StyledItem>
            <StyledItemLabel>
              <FormattedMessage
                id="ContractAside.Notas importantes"
                defaultMessage="Notas importantes"
              />
            </StyledItemLabel>
            <StyledItemData>
              {contractQuery.isLoading ? (
                <Skeleton />
              ) : (
                contractQuery.data?.Notas_importantes ?? "-"
              )}
            </StyledItemData>
          </StyledItem>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion elevation={0} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <DescriptionIcon />
          <FormattedMessage
            id="ContractAside.Timeline"
            defaultMessage="Timeline"
          />
        </AccordionSummary>
        <AccordionDetails>
          <ContractEventsTimeline
            events={eventsQuery.data?.events ?? []}
            status={eventsQuery.status}
          />
        </AccordionDetails>
      </StyledAccordion>
    </StyledWrapper>
  );
};

export default ContractAside;
