import { Tab, Tabs } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import moment from "moment";
import "moment/locale/es";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { CONTRACT_PHASE } from "../../../../../../api/contract/contract.types";
import BadgeIcon from "../../../../../../assets/images/iconos/badge.png";
import CalendarIcon from "../../../../../../assets/images/iconos/calendar.png";
import EditIcon from "../../../../../../assets/images/iconos/edit.png";
import ZohoIcon from "../../../../../../assets/images/iconos/zoho.png";
import ContratoTooltip from "../../../../../../components/Contrato/ContratoTooltip";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import { APP_START_PAGE } from "../../../../../../util/defaults/app";
import { TUpdateContractPhase } from "../../routes/ContractsPage/hooks/useContractPhaseMutation";
import ContratoDetailedTooltip from "../ContractDetailedTooltip/ContractDetailedTooltip";
import ContractEditModal from "../ContractEditModal";
import PublicadoStatus from "../../../../components/PublicadoStatus";
import StyledContratoTipoDeTrato from "../../../../components/StyledContratoTipoDeTrato";
import ContractDemoteActions from "./ContractDemoteActions";
import {
  StyledActionIcon,
  StyledActionLink,
  StyledActionLinkText,
  StyledArrowBackIosIcon,
  StyledContractStatus,
  StyledContractTitle,
  StyledContratoTitleDetails,
  StyledDivider,
  StyledFirstRow,
  StyledFirstRowLeft,
  StyledFirstRowRight,
  StyledGrowSection,
  StyledLink,
  StyledRowSection,
  StyledSecondRow,
  StyledSecondRowLeft,
  StyledSection,
  StyledTitleRowSection,
  StyledTriData,
  StyledTriDataLabel,
  StyledTriDataWrapper,
  StyledTriIcon,
  StyledTriItem,
  StyledWrapper,
} from "./ContractHeader.SC";
import { TContractFull } from "../../types/contract.types";
import {
  contractHasPastTargetDate,
  contractIsExpress,
} from "../../util/contract.util";
import classNames from "classnames";
import PlanTag from "./PlanTag";
import ExpressTag from "./ExpressTag";
import { VALUATION_STATUS } from "../../../../../../api/valuation/valuation.types";
import { ManagerLink } from "../ManagerLink/ManagerLink";

const StyledSecondRowRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const StyledHeaderDemoteActions = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
`;

const StyledPublishInfo = styled.div``;

const StyledTabs = styled(Tabs)`
  min-height: 2.5rem;
  .MuiTab-root {
    background-color: rgba(55, 55, 55, 0.2);
    color: ${cuideoTheme.palette.primary.main};
    min-width: 8rem;

    &:hover {
      background-color: rgba(0, 72, 120, 0.75);
      color: ${cuideoTheme.palette.common.white};
    }
  }
  .Mui-selected {
    font-weight: 700;
    background-color: #01253d;
    color: ${cuideoTheme.palette.common.white};
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  text-transform: none;
  font-weight: 400;
  border-radius: 0.5rem 0.5rem 0 0;
  min-height: 2.5rem;

  .MuiTab-wrapper {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .MuiSvgIcon-fontSizeSmall {
      font-size: 1.25rem;
    }
  }
`;

export interface ContractHeaderProps {
  contract: TContractFull;
  estadoEnProceso: string;
  setEstadoFilter: Function;
  onChange: any;
  canAddEmployees?: boolean;
  onContractPhaseUpdate?: (payload: TUpdateContractPhase) => void;
  currentTime?: Date;
}

class ContractHeader extends Component<ContractHeaderProps, any> {
  constructor(props: ContractHeaderProps) {
    super(props);

    this.state = {
      editOpen: false,
    };

    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleDemote = this.handleDemote.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }

  shouldComponentUpdate(nextProps: ContractHeaderProps, nextState: any) {
    if (this.props.contract !== nextProps.contract) {
      return true;
    }
    if (this.props.estadoEnProceso !== nextProps.estadoEnProceso) {
      return true;
    }
    if (this.props.canAddEmployees !== nextProps.canAddEmployees) {
      return true;
    }
    if (this.state.editOpen !== nextState.editOpen) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    window.dispatchEvent(new Event("resize"));
  }

  handleTabChange(e: React.ChangeEvent<{}>, index: number) {
    if (index === 0) {
      this.props.setEstadoFilter(VALUATION_STATUS.NOT_VALUED);
    }
    if (index === 1) {
      this.props.setEstadoFilter(VALUATION_STATUS.DISCARDED);
    }
    if (index === 2) {
      this.props.setEstadoFilter(VALUATION_STATUS.SEARCH);
    }
  }

  handleEditClose() {
    this.setState({ ...this.state, editOpen: false });
  }

  handleDemote() {
    this.props.onContractPhaseUpdate?.({
      id: this.props.contract.id,
      contractName: this.props.contract.Name,
      phase: CONTRACT_PHASE.RECRUIT,
    });
  }

  handleShare() {
    this.props.onContractPhaseUpdate?.({
      id: this.props.contract.id,
      contractName: this.props.contract.Name,
      phase: CONTRACT_PHASE.RECRUIT_AND_PROMOTE,
    });
  }

  render() {
    const {
      contract,
      estadoEnProceso,
      canAddEmployees,
      currentTime = new Date(),
    } = this.props;

    let mainTabValue = 0;
    if (estadoEnProceso === VALUATION_STATUS.DISCARDED) {
      mainTabValue = 1;
    } else if (estadoEnProceso === VALUATION_STATUS.SEARCH) {
      mainTabValue = 2;
    } else {
      mainTabValue = 0;
    }

    const hasPastTargetDate = contractHasPastTargetDate(contract, currentTime);

    return (
      <StyledWrapper>
        <StyledSection>
          <StyledLink
            to={APP_START_PAGE}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              window.history.back();
            }}
          >
            <StyledArrowBackIosIcon />
          </StyledLink>
        </StyledSection>
        <StyledDivider />
        <StyledGrowSection>
          <StyledFirstRow>
            <StyledFirstRowLeft>
              <StyledRowSection>
                {contract.Tipo_de_Trato && (
                  <StyledContratoTitleDetails>
                    <StyledContratoTipoDeTrato tipo={contract.Tipo_de_Trato} />
                  </StyledContratoTitleDetails>
                )}
              </StyledRowSection>
              <StyledRowSection>
                <PlanTag contract={contract} />
              </StyledRowSection>
              {contractIsExpress(contract) && (
                <StyledRowSection>
                  <ExpressTag contract={contract} />
                </StyledRowSection>
              )}
              <StyledRowSection>
                <StyledPublishInfo>
                  <PublicadoStatus contrato={contract} />
                </StyledPublishInfo>
              </StyledRowSection>
              <StyledRowSection>
                <StyledActionLink
                  color="primary"
                  onClick={() => {
                    this.setState({ ...this.state, editOpen: true });
                  }}
                >
                  <StyledActionIcon src={EditIcon} />
                  <StyledActionLinkText>
                    <FormattedMessage
                      id="ContractHeader.EditOffer"
                      defaultMessage="Editar oferta"
                    />
                  </StyledActionLinkText>
                </StyledActionLink>
                <ContractEditModal
                  contractId={contract.id}
                  open={this.state.editOpen}
                  onClose={this.handleEditClose}
                  onChange={this.props.onChange}
                />
              </StyledRowSection>
              <StyledRowSection>
                <ContratoTooltip contrato={contract} />
              </StyledRowSection>
              <StyledRowSection>
                <StyledContractStatus>
                  <FormattedMessage
                    id="ContractHeader.Status"
                    defaultMessage="Estado:"
                  />
                  &nbsp;{contract.Estado}
                </StyledContractStatus>
              </StyledRowSection>
              <StyledRowSection>
                <StyledActionLink
                  color="primary"
                  onClick={() => {
                    window.open(
                      `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contract.zohoId}`
                    );
                  }}
                >
                  <StyledActionIcon src={ZohoIcon} />
                  <StyledActionLinkText>
                    <FormattedMessage
                      id="ContractHeader.ViewInZoho"
                      defaultMessage="Ver en Zoho"
                    />
                  </StyledActionLinkText>
                </StyledActionLink>
              </StyledRowSection>
              <StyledRowSection>
                <ManagerLink zohoId={contract.zohoId} type="link" />
              </StyledRowSection>
            </StyledFirstRowLeft>
            <StyledFirstRowRight>
              <StyledRowSection>
                <StyledTriItem>
                  <StyledTriIcon src={CalendarIcon} />
                  <StyledTriDataWrapper>
                    <StyledTriDataLabel>
                      <FormattedMessage
                        id="ContractHeader.Fecha objetivo"
                        defaultMessage="Fecha objetivo"
                      />
                    </StyledTriDataLabel>
                    <StyledTriData
                      className={classNames({
                        "CMui-warning": hasPastTargetDate,
                      })}
                    >
                      {moment(contract.Fecha_objetivo).format("DD/MM/YYYY")}
                    </StyledTriData>
                  </StyledTriDataWrapper>
                </StyledTriItem>
              </StyledRowSection>
              {!!contract.Servicio?.ownerName && (
                <StyledRowSection>
                  <StyledTriItem>
                    <StyledTriIcon src={BadgeIcon} />
                    <StyledTriDataWrapper>
                      <StyledTriDataLabel>
                        <FormattedMessage
                          id="ContractHeader.Propietario servicio"
                          defaultMessage="Propietario servicio"
                        />
                      </StyledTriDataLabel>
                      <StyledTriData>
                        {contract.Servicio.ownerName}
                      </StyledTriData>
                    </StyledTriDataWrapper>
                  </StyledTriItem>
                </StyledRowSection>
              )}
              <StyledRowSection>
                <StyledTriItem>
                  <StyledTriIcon src={BadgeIcon} />
                  <StyledTriDataWrapper>
                    <StyledTriDataLabel>
                      <FormattedMessage
                        id="ContractHeader.Propietario contrato"
                        defaultMessage="Propietario contrato"
                      />
                    </StyledTriDataLabel>
                    <StyledTriData>{contract.ownerName}</StyledTriData>
                  </StyledTriDataWrapper>
                </StyledTriItem>
              </StyledRowSection>
            </StyledFirstRowRight>
          </StyledFirstRow>
          <StyledSecondRow>
            <StyledSecondRowLeft>
              <StyledTitleRowSection>
                <StyledContractTitle>{contract.T_tulo}</StyledContractTitle>
                <ContratoDetailedTooltip contract={contract} />
              </StyledTitleRowSection>
            </StyledSecondRowLeft>

            <StyledSecondRowRight>
              <StyledHeaderDemoteActions>
                <ContractDemoteActions
                  canDemote={contract.phase !== "recruit"}
                  onContractDemote={this.handleDemote}
                  updatingAction={contract._uiMutation as any}
                />
              </StyledHeaderDemoteActions>
              <StyledTabs value={mainTabValue} onChange={this.handleTabChange}>
                <StyledTab
                  label={
                    <Fragment>
                      <AttachFileIcon fontSize="small" />
                      <FormattedMessage
                        id="ContractHeader.{numInscritos} inscritos"
                        defaultMessage="{numInscritos} inscritos"
                        values={{ numInscritos: contract.numInscritos }}
                      />
                    </Fragment>
                  }
                  disabled={false}
                ></StyledTab>
                <StyledTab
                  label={
                    <Fragment>
                      <DeleteOutlineIcon fontSize="small" />
                      <FormattedMessage
                        id="ContractHeader.{numEliminadas} Descartadas"
                        defaultMessage="{numEliminadas} Descartadas"
                        values={{ numEliminadas: contract.numDescartados }}
                      />
                    </Fragment>
                  }
                  disabled={contract.numDescartados === 0}
                ></StyledTab>
                {canAddEmployees && (
                  <StyledTab
                    label={
                      <Fragment>
                        <AddIcon fontSize="small" />
                        <FormattedMessage
                          id="ContractHeader.Añadir"
                          defaultMessage="Añadir"
                        />
                      </Fragment>
                    }
                    disabled={false}
                  ></StyledTab>
                )}
              </StyledTabs>
            </StyledSecondRowRight>
          </StyledSecondRow>
        </StyledGrowSection>
      </StyledWrapper>
    );
  }
}

export default ContractHeader;
